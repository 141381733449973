import React from "react";

import { Alert, Snackbar } from "@mui/material";

import { ToastProps } from "./types";

const Toast: React.FC<ToastProps> = ({
  index,
  message,
  autoHideDuration,
  onExited,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const verticalOffset = 20 + index * 60;

  return (
    <Snackbar
      key={message.key}
      open={open}
      onClose={handleClose}
      TransitionProps={{ onExited }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={autoHideDuration ?? 5000}
      style={{ top: `${verticalOffset}px` }}
      {...props}
    >
      <Alert severity={message.severity} onClose={handleClose}>
        {message.message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
