import http from "@/api/http";

import {
  postFormAnswerPayload,
  postFormAnswerResponse,
  getFormAnswerDownloadPayload,
  getFormAnswerDownloadResponse,
} from "./types";

export const postFormAnswer = async (
  payload: postFormAnswerPayload
): Promise<postFormAnswerResponse> => {
  const response = await http.post<postFormAnswerResponse>(
    "/form-answer/",
    payload
  );

  return response.data;
};

export const getFormAnswerDownload = async (
  payload: getFormAnswerDownloadPayload
): Promise<getFormAnswerDownloadResponse> => {
  const response = await http.get<getFormAnswerDownloadResponse>(
    "/form-answer/download/",
    { params: payload }
  );

  return response.data;
};
