import React from "react";

import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";

import { CheckboxGroupInputProps } from "./types";

export function CheckboxGroupInput({
  field,
  form,
  title,
  options,
  required,
  singleSelection = false,
  disabled,
  row = false,
  checkboxProps,
  radioProps,
}: CheckboxGroupInputProps) {
  const { name, value } = field;
  const { setFieldValue } = form;

  const meta = form.getFieldMeta(name);
  const { touched, error } = meta;

  const handleChangeMultiple = (optionValue: any) => {
    const currentValue = value || [];
    if (Array.isArray(currentValue)) {
      if (currentValue.includes(optionValue)) {
        setFieldValue(
          name,
          currentValue.filter((v: any) => v !== optionValue)
        );
      } else {
        setFieldValue(name, [...currentValue, optionValue]);
      }
    } else {
      setFieldValue(name, [optionValue]);
    }
  };

  const handleChangeSingle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, event.target.value);
  };

  return (
    <FormControl
      component="fieldset"
      error={touched && Boolean(error)}
      fullWidth
    >
      <Typography
        variant="subtitle1"
        gutterBottom
        color={touched && error ? "error" : "inherit"}
      >
        {title}
        {required && (
          <Typography display="inline" color="error" marginLeft={1}>
            *
          </Typography>
        )}
      </Typography>
      {singleSelection ? (
        <RadioGroup
          name={name}
          value={value || ""}
          onChange={handleChangeSingle}
          row={row}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={
                <Radio
                  disabled={disabled}
                  {...radioProps}
                  sx={{
                    color: touched && error ? "error.main" : "inherit",
                    "&.Mui-checked": {
                      color: touched && error ? "error.main" : "primary.main",
                    },
                  }}
                />
              }
              label={option.label}
              sx={{ color: touched && error ? "error.main" : "inherit" }}
            />
          ))}
        </RadioGroup>
      ) : (
        <FormGroup row={row}>
          {options.map((option) => {
            const isChecked =
              Array.isArray(value) && value.includes(option.value);

            return (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    onChange={() => handleChangeMultiple(option.value)}
                    checked={isChecked}
                    value={option.value}
                    name={name}
                    disabled={disabled}
                    {...checkboxProps}
                    sx={{
                      color: touched && error ? "error.main" : "inherit",
                      "&.Mui-checked": {
                        color: touched && error ? "error.main" : "primary.main",
                      },
                    }}
                  />
                }
                label={option.label}
                sx={{ color: touched && error ? "error.main" : "inherit" }}
              />
            );
          })}
        </FormGroup>
      )}
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
