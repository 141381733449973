import React from "react";

import { DateTime } from "luxon";

import { getIn } from "formik";

import { FormControl, Typography } from "@mui/material";
import {
  TimePickerProps as MuiTimePickerProps,
  TimePicker as MuiTimePicker,
} from "@mui/x-date-pickers/TimePicker";

import { createErrorHandler } from "@/utils/error";

import { TimePickerInputProps } from "./types";

export function fieldToTimePickerInput({
  field,
  form,
  textField = {},
  placeholder = "",
  disabled,
  label,
  required,
  onChange,
  onError,
  ...props
}: TimePickerInputProps): MuiTimePickerProps<DateTime> {
  const { name, value: fieldValue } = field;

  const {
    isSubmitting,
    touched,
    errors,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = form;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  const onBlurDefault = () => {
    setFieldTouched(name, true, true);
  };

  const onChangeDefault = (date: DateTime | null) => {
    setFieldTouched(name, true, false);
    setFieldValue(name, date, true);
  };

  const value: DateTime | null = fieldValue || null;

  return {
    value,
    disabled: disabled ?? isSubmitting,
    onChange: onChange ?? onChangeDefault,
    onError: onError ?? createErrorHandler(fieldError, name, setFieldError),
    slotProps: {
      textField: {
        error: showError,
        helperText: showError ? fieldError : textField.helperText,
        label,
        onBlur: textField.onBlur ?? onBlurDefault,
        placeholder: placeholder,
        ...textField,
      },
    },
    ...props,
  };
}

export function TimePickerInput(props: TimePickerInputProps) {
  const { label, required, ...rest } = props;
  const timePickerProps = fieldToTimePickerInput(rest);

  const touched = getIn(props.form.touched, props.field.name);
  const error = getIn(props.form.errors, props.field.name);

  return (
    <FormControl fullWidth>
      {label && (
        <Typography
          variant="subtitle1"
          gutterBottom
          color={touched && error ? "error" : "inherit"}
        >
          {label}
          {required && (
            <Typography display="inline" color="error" marginLeft={1}>
              *
            </Typography>
          )}
        </Typography>
      )}
      <MuiTimePicker {...timePickerProps} />
    </FormControl>
  );
}
