import { AxiosError } from "axios";
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  QueryFunction,
  InfiniteData,
} from "@tanstack/react-query";

import { getUsers, getUser } from "./services";
import {
  getUsersPayload,
  getUsersResponse,
  getUserPayload,
  getUserResponse,
} from "./types";

export const useQueryUsers = (
  payload: getUsersPayload,
  options?: Omit<
    UseQueryOptions<getUsersResponse, AxiosError>,
    "queryKey" | "queryFn"
  >
) => {
  const queryKey: QueryKey = ["users", payload];
  const queryFn = () => getUsers(payload);

  return useQuery<getUsersResponse, AxiosError>({
    queryKey,
    queryFn,
    ...options,
  });
};

export const useInfiniteQueryUsers = (
  payload: Omit<getUsersPayload, "page" | "pageSize">,
  options?: Omit<
    UseInfiniteQueryOptions<
      getUsersResponse,
      AxiosError,
      InfiniteData<getUsersResponse>
    >,
    "queryKey" | "queryFn" | "getNextPageParam"
  >
) => {
  const queryKey: QueryKey = ["users-infinite", payload];

  const queryFn: QueryFunction<getUsersResponse, QueryKey, unknown> = ({
    pageParam = 1,
  }) =>
    getUsers({
      ...payload,
      page: pageParam as number,
      pageSize: 10,
    });

  return useInfiniteQuery<
    getUsersResponse,
    AxiosError,
    InfiniteData<getUsersResponse>
  >({
    queryKey,
    queryFn,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.pagination.currentPage < lastPage.pagination.totalPages) {
        return lastPage.pagination.currentPage + 1;
      }
      return undefined;
    },
    ...options,
  });
};

export const useQueryUser = (
  payload: getUserPayload,
  options?: Omit<
    UseQueryOptions<getUserResponse, AxiosError>,
    "queryKey" | "queryFn"
  >
) => {
  const queryKey: QueryKey = ["user", payload];
  const queryFn = () => getUser(payload);

  return useQuery<getUserResponse, AxiosError>({
    queryKey,
    queryFn,
    ...options,
  });
};
