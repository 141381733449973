import React from "react";

import { Stack, Typography } from "@mui/material";

import { SchoolResearcher } from "@/types/School";

interface SchoolInfoProps {
  schoolResearcher: SchoolResearcher | undefined;
}

const SchoolInfo: React.FC<SchoolInfoProps> = ({ schoolResearcher }) => (
  <Stack textAlign="left" spacing={1}>
    <Typography
      variant="h6"
      fontWeight="bold"
      textOverflow="ellipsis"
      overflow="hidden"
      whiteSpace="nowrap"
    >
      {schoolResearcher?.dsName}
    </Typography>
    <Typography variant="subtitle1">
      <Typography component="span" variant="subtitle1" fontWeight="bold">
        Localização:
      </Typography>{" "}
      {schoolResearcher?.dsCity ?? "Cidade não informada"} -{" "}
      {schoolResearcher?.dsState ?? "Estado não informado"}
    </Typography>
    <Typography variant="subtitle1">
      <Typography component="span" variant="subtitle1" fontWeight="bold">
        Código INEP:
      </Typography>{" "}
      {schoolResearcher?.dsInepCode}
    </Typography>
  </Stack>
);

export default SchoolInfo;
