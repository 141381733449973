import http from '@/api/http'

import { postAuthLoginPayload, postAuthLoginResponse, postAuthLogoutResponse } from './types'

export const postAuthLogin = async (payload: postAuthLoginPayload): Promise<postAuthLoginResponse> => {
  const response = await http.post<postAuthLoginResponse>('/auth/login/', payload)
  
  return response.data
}

export const postAuthLogout = async (): Promise<postAuthLogoutResponse> => {
  const response = await http.post<postAuthLogoutResponse>('/auth/logout/', {
    refreshToken: localStorage.getItem('refresh-token') || sessionStorage.getItem('refresh-token') || '',
  })

  return response.data
}
