import { Question } from "@/types/Form";

export const getNestedValue = (path: string, values: Record<string, any>): any => {
  return path
    .split(".")
    .reduce((acc, key) => (acc ? acc[key] : undefined), values);
};

export const getNestedValueString = (path: string, values: Record<string, any>): string => {
  const value = getNestedValue(path, values);
  
  if (typeof value === "number") {
    return value.toString();
  }

  if (typeof value === "boolean") {
    return value ? "Sim" : "Não";
  }
  
  if (Array.isArray(value)) {
    return value.join(", ");
  }

  return value;
}

export const checkVisibility = (
  question: Question,
  values: Record<string, any>
): boolean => {
  const visibleIf = question.visibleIf;

  if (!visibleIf) return true;

  const parentValue = getNestedValue(visibleIf?.questionId || "", values);

  const { selectedOptionsInclude, selectedOptionsExclude, equals } = visibleIf;

  let visible = true;

  if (selectedOptionsInclude) {
    if (Array.isArray(parentValue)) {
      visible = selectedOptionsInclude.some((option) =>
        parentValue.includes(option)
      );
    } else {
      visible = selectedOptionsInclude.includes(parentValue);
    }
  }

  if (selectedOptionsExclude) {
    if (Array.isArray(parentValue)) {
      visible =
        visible &&
        !selectedOptionsExclude.some((option) =>
          parentValue.includes(option)
        );
    } else {
      visible = visible && !selectedOptionsExclude.includes(parentValue);
    }
  }

  if (equals !== undefined) {
    visible = visible && parentValue === equals;
  }

  return visible;
};
