// src/components/WithInterview/NavigationButtons.tsx
import React from "react";
import { Stack, Button } from "@mui/material";

interface NavigationButtonsProps {
  isLastBlock: boolean;
  isSubmitting: boolean;
  onSubmit: () => void;
  onGoBackBlock: () => void;
  showGoBack: boolean;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  isLastBlock,
  isSubmitting,
  onSubmit,
  onGoBackBlock,
  showGoBack,
}) => (
  <Stack spacing={2} mt={2}>
    <Button
      type="button"
      variant="contained"
      onClick={onSubmit}
      disabled={isSubmitting}
      fullWidth
      disableElevation
    >
      {isLastBlock ? "Finalizar entrevista" : "Seguir para o próximo bloco"}
    </Button>
    {showGoBack && (
      <Button
        variant="outlined"
        onClick={onGoBackBlock}
        disabled={isSubmitting}
        fullWidth
        disableElevation
      >
        Voltar
      </Button>
    )}
  </Stack>
);

export default NavigationButtons;
