import { AxiosError } from "axios";
import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { getFeedbackDownload } from "./services";
import {
  getFeedbackDownloadPayload,
  getFeedbackDownloadResponse,
} from "./types";

export const useQueryFeedbackDownload = (
  payload: getFeedbackDownloadPayload,
  options?: Omit<
    UseQueryOptions<getFeedbackDownloadResponse, AxiosError>,
    "queryKey" | "queryFn"
  >
) => {
  const queryKey: QueryKey = ["feedback-download", payload];
  const queryFn = () => getFeedbackDownload(payload);

  return useQuery<getFeedbackDownloadResponse, AxiosError>({
    queryKey,
    queryFn,
    ...options,
  });
};
