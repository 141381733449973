import { AxiosError } from "axios";
import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { getFormAnswerDownload } from "./services";
import {
  getFormAnswerDownloadPayload,
  getFormAnswerDownloadResponse,
} from "./types";

export const useQueryFormAnswerDownload = (
  payload: getFormAnswerDownloadPayload,
  options?: Omit<
    UseQueryOptions<getFormAnswerDownloadResponse, AxiosError>,
    "queryKey" | "queryFn"
  >
) => {
  const queryKey: QueryKey = ["form-answer-download", payload];
  const queryFn = () => getFormAnswerDownload(payload);

  return useQuery<getFormAnswerDownloadResponse, AxiosError>({
    queryKey,
    queryFn,
    ...options,
  });
};
