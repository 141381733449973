import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { postFeedback } from './services'
import {
  postFeedbackPayload,
  postFeedbackResponse,
} from './types'

export const useMutationPostFeedback = (
  options?: UseMutationOptions<postFeedbackResponse, AxiosError, postFeedbackPayload>,
) => {
  return useMutation({ ...options, mutationFn: postFeedback })
}
