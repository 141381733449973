import React from "react";

import { Stack, CircularProgress, Typography } from "@mui/material";

const LoadingState: React.FC = () => (
  <Stack
    direction="row"
    justifyContent="center"
    alignItems="center"
    height="100%"
    spacing={2}
  >
    <CircularProgress size="2rem" />
    <Typography>Carregando...</Typography>
  </Stack>
);

export default LoadingState;
