import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { Field, Form, Formik, FormikHelpers } from "formik";

import {
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
  MenuItem,
  FormControl,
  Divider,
  Breadcrumbs,
  Link,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  Search,
  Block,
  CheckCircleOutline,
  Edit,
  Autorenew,
  KeyboardArrowRight,
  Home,
  Add,
} from "@mui/icons-material";

import { SelectInput } from "@/components/Inputs/SelectInput";
import { TextInput } from "@/components/Inputs/TextInput";

import { useToast } from "@/contexts/ToastContext";
import { useModal } from "@/contexts/ModalContext";

import DataTable from "@/common/components/DataTable";

import { statesLabels, statesOptions } from "@/data/states";

import { User } from "@/types/User";

import { useInfiniteQueryUsers } from "@/api/user/queries";
import {
  useMutationPutUserBlock,
  useMutationPutUserUnblock,
  useMutationPostUserNewPassword,
} from "@/api/user/mutations";

import { ProfileEnum } from "@/enums/Profile";

interface UsersFormData {
  state: string;
  search: string;
}

const initialValues: UsersFormData = {
  state: "",
  search: "",
};

const validationSchema = Yup.object({
  state: Yup.string(),
  search: Yup.string(),
});

const UserActions = ({
  id,
  name,
  isBlocked,
  refetchUsers,
}: {
  id: number;
  name: string;
  isBlocked?: boolean;
  refetchUsers?: () => void;
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { openModal } = useModal();

  const { mutateAsync: mutatePutUserBlock } = useMutationPutUserBlock();
  const { mutateAsync: mutatePutUserUnblock } = useMutationPutUserUnblock();
  const { mutateAsync: mutatePostUserNewPassword } =
    useMutationPostUserNewPassword();

  const handleBlock = () => {
    openModal({
      title: "Bloquear Usuário",
      description: `Deseja realmente bloquear o usuário ${name}?`,
      onConfirmed: () => {
        mutatePutUserBlock({ coUser: id })
          .then(() => {
            toast.show("Usuário bloqueado com sucesso!", {
              severity: "success",
            });

            if (refetchUsers) {
              refetchUsers();
            }
          })
          .catch(() => {
            toast.show("Ocorreu um erro ao bloquear o usuário!", {
              severity: "error",
            });
          });
      },
    });
  };

  const handleUnblock = () => {
    openModal({
      title: "Desbloquear Usuário",
      description: `Deseja realmente desbloquear o usuário "${name}"?`,
      onConfirmed: () => {
        mutatePutUserUnblock({ coUser: id })
          .then(() => {
            toast.show("Usuário desbloqueado com sucesso!", {
              severity: "success",
            });

            if (refetchUsers) {
              refetchUsers();
            }
          })
          .catch(() => {
            toast.show("Ocorreu um erro ao desbloquear o usuário!", {
              severity: "error",
            });
          });
      },
    });
  };

  const handleEdit = () => {
    navigate(`/gerenciamento/coordenador/usuario/editar/${id}`);
  };

  const handleReset = () => {
    openModal({
      title: "Redefinir Senha",
      description: `Deseja realmente redefinir a senha do usuário "${name}"?`,
      onConfirmed: () => {
        mutatePostUserNewPassword({ coUser: id })
          .then(() => {
            toast.show("Senha redefinida com sucesso!", {
              severity: "success",
            });
          })
          .catch(() => {
            toast.show("Ocorreu um erro ao redefinir a senha!", {
              severity: "error",
            });
          });
      },
    });
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <IconButton color="primary" onClick={handleReset}>
        <Autorenew />
      </IconButton>
      <IconButton color="primary" onClick={handleEdit}>
        <Edit />
      </IconButton>
      {isBlocked ? (
        <IconButton color="success" onClick={handleUnblock}>
          <CheckCircleOutline />
        </IconButton>
      ) : (
        <IconButton color="error" onClick={handleBlock}>
          <Block />
        </IconButton>
      )}
    </Stack>
  );
};

const View = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [state, setState] = useState("");

  const [allUsers, setAllUsers] = useState([] as User[]);

  const {
    data: infiniteUsers,
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useInfiniteQueryUsers({
    dsName: search,
    dsState: state,
  });

  const headers = [
    { name: "Nome", key: "dsName" },
    { name: "E-mail", key: "dsEmail" },
    { name: "Cidade", key: "dsCity" },
    { name: "Estado", key: "dsState" },
    { name: "Tipo", key: "profile" },
    {
      name: "Ações",
      key: "actions",
      type: "element",
      align: "center",
    },
  ];

  const createCoordinator = () => {
    navigate("/gerenciamento/coordenador/usuario/criar");
  };

  const handleOnSubmit = (
    values: UsersFormData,
    { setSubmitting }: FormikHelpers<UsersFormData>
  ) => {
    const { state, search } = values;

    setSearch(search);
    setState(state);

    setSubmitting(false);
  };

  const getProfileName = (profile?: number) => {
    if (!profile) {
      return "Perfil inválido";
    }

    switch (profile) {
      case ProfileEnum.manager:
        return "Gestor";
      case ProfileEnum.coordinator:
        return "Coordenador";
      case ProfileEnum.researcher:
        return "Pesquisador";
      default:
        return "Perfil inválido";
    }
  };

  useEffect(() => {
    if (!infiniteUsers) return;

    const flatDashboardSchoolsPending = infiniteUsers.pages.flatMap(
      (page) => page.results
    );

    const allUsers = flatDashboardSchoolsPending.map((user) => {
      return {
        ...user,
        dsState:
          statesLabels[
            user.dsState.toUpperCase() as keyof typeof statesLabels
          ] || "(Estado inválido)",
        profile: getProfileName(user.coProfile),
        actions: (
          <UserActions
            id={user.coUser}
            name={user.dsName}
            isBlocked={!user.stStatus}
            refetchUsers={refetchUsers}
          />
        ),
      };
    });

    setAllUsers(allUsers);
  }, [infiniteUsers]);

  return (
    <Stack direction="column" spacing={2}>
      <Box
        padding={2}
        borderRadius={4}
        bgcolor="white"
        sx={{ border: 1, borderColor: "divider" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Usuários</Typography>

          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<KeyboardArrowRight fontSize="small" />}
          >
            <Link
              href="/"
              color="black"
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Home fontSize="small" />
            </Link>
            <Link
              href="/gerenciamento/coordenador/usuarios"
              color="black"
              underline="hover"
            >
              <Typography color="black">Usuários</Typography>
            </Link>
            <Typography color="grey">Listagem</Typography>
          </Breadcrumbs>
        </Stack>
      </Box>

      <Box
        borderRadius={4}
        bgcolor="white"
        sx={{ border: 1, borderColor: "divider" }}
      >
        <Box borderRadius={4} overflow="hidden">
          <Stack bgcolor="white" spacing={3} sx={{ boxShadow: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              padding={3}
              paddingBottom={0}
            >
              <Typography variant="h5">Listagem de Usuários</Typography>
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<Add />}
                  onClick={createCoordinator}
                  disableElevation
                >
                  Cadastrar Usuário
                </Button>
              </Box>
            </Stack>
            <Divider />
            <Stack spacing={3} padding={3} paddingTop={0}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleOnSubmit}
              >
                {({ setFieldValue, isSubmitting }) => (
                  <Box component={Form} width="100%">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Stack direction="row" spacing={2}>
                        <FormControl sx={{ width: 400 }}>
                          <Field
                            component={TextInput}
                            name="search"
                            size="small"
                            placeholder="Pesquisar por nome"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Search />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                        <FormControl sx={{ width: 200 }}>
                          <Field
                            component={SelectInput}
                            name="state"
                            size="small"
                            variant="outlined"
                            displayEmpty
                          >
                            <MenuItem value="">Selecione um estado</MenuItem>
                            {statesOptions.map((state) => (
                              <MenuItem key={state.value} value={state.value}>
                                {state.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={() => {
                            setFieldValue("state", "");
                            setFieldValue("search", "");
                            setSearch("");
                            setState("");
                          }}
                          sx={{ paddingX: 4 }}
                        >
                          Limpar
                        </Button>
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          disableElevation
                          loading={isSubmitting}
                          sx={{ paddingX: 4 }}
                        >
                          Filtrar
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  </Box>
                )}
              </Formik>
              <Stack direction="column">
                <Divider />
                <DataTable headers={headers} rowId="coUser" data={allUsers} />
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

export default View;
