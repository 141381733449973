import http from "@/api/http";

import {
  getDashboardGeneralByStateResponse,
  getDashboardSchoolsByStatusPayload,
  getDashboardSchoolsByStatusResponse,
} from "./types";

export const getDashboardGeneralByState =
  async (): Promise<getDashboardGeneralByStateResponse> => {
    const response = await http.get<getDashboardGeneralByStateResponse>(
      "/dashboard/general_by_state/"
    );

    return response.data;
  };

export const getDashboardSchoolsByStatus = async (
  payload: getDashboardSchoolsByStatusPayload
): Promise<getDashboardSchoolsByStatusResponse> => {
  const response = await http.get<getDashboardSchoolsByStatusResponse>(
    "/dashboard/schools_by_status/",
    {
      params: payload,
    }
  );

  return response.data;
};
