import { AxiosError } from "axios";
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  QueryFunction,
  InfiniteData,
} from "@tanstack/react-query";

import {
  getDashboardGeneralByState,
  getDashboardSchoolsByStatus,
} from "./services";
import {
  getDashboardGeneralByStateResponse,
  getDashboardSchoolsByStatusPayload,
  getDashboardSchoolsByStatusResponse,
} from "./types";

export const useQueryDashboardGeneralByState = (
  options?: Omit<
    UseQueryOptions<getDashboardGeneralByStateResponse, AxiosError>,
    "queryKey" | "queryFn"
  >
) => {
  const queryKey: QueryKey = ["dashboard-general-by-state"];
  const queryFn = () => getDashboardGeneralByState();

  return useQuery<getDashboardGeneralByStateResponse, AxiosError>({
    queryKey,
    queryFn,
    ...options,
  });
};

export const useQueryDashboardSchoolsByStatus = (
  payload: getDashboardSchoolsByStatusPayload,
  options?: Omit<
    UseQueryOptions<getDashboardSchoolsByStatusResponse, AxiosError>,
    "queryKey" | "queryFn"
  >
) => {
  const queryKey: QueryKey = ["dashboard-schools-by-status", payload];
  const queryFn = () => getDashboardSchoolsByStatus(payload);

  return useQuery<getDashboardSchoolsByStatusResponse, AxiosError>({
    queryKey,
    queryFn,
    ...options,
  });
};

export const useInfiniteQueryDashboardSchoolsByStatus = (
  payload: Omit<getDashboardSchoolsByStatusPayload, "page" | "pageSize">,
  options?: Omit<
    UseInfiniteQueryOptions<
      getDashboardSchoolsByStatusResponse,
      AxiosError,
      InfiniteData<getDashboardSchoolsByStatusResponse>
    >,
    "queryKey" | "queryFn" | "getNextPageParam"
  >
) => {
  const queryKey: QueryKey = ["dashboard-schools-by-status-infinite", payload];

  const queryFn: QueryFunction<
    getDashboardSchoolsByStatusResponse,
    QueryKey,
    unknown
  > = ({ pageParam = 1 }) =>
    getDashboardSchoolsByStatus({
      ...payload,
      page: pageParam as number,
      pageSize: 10,
    });

  return useInfiniteQuery<
    getDashboardSchoolsByStatusResponse,
    AxiosError,
    InfiniteData<getDashboardSchoolsByStatusResponse>
  >({
    queryKey,
    queryFn,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.pagination.currentPage < lastPage.pagination.totalPages) {
        return lastPage.pagination.currentPage + 1;
      }
      return undefined;
    },
    ...options,
  });
};
