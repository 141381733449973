import React from "react";

import { Outlet } from "react-router-dom";

import { Container, Card, Box, Stack } from "@mui/material";

const TemplateAuth: React.FC = () => {
  return (
    <Container maxWidth="sm">
      <Stack justifyContent="center" alignItems="center" height="100svh">
        <Stack
          justifyContent="center"
          alignItems="center"
          flex="1 1 0"
          width="100%"
        >
          <Card
            variant="outlined"
            sx={{
              width: {
                xs: "100%",
                sm: 450,
              },
              padding: 4,
              borderRadius: 5,
            }}
          >
            <Outlet />
          </Card>
        </Stack>

        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          paddingY={2}
          spacing={2}
          bottom={0}
        >
          <Box
            component="img"
            alt="Logo FNDE"
            src="/assets/img/logo-fnde.png"
            sx={{
              objectFit: "cover",
              height: {
                xs: 30,
                sm: 50,
              },
            }}
          />
          <Box
            component="img"
            alt="Logo NEES"
            src="/assets/img/logo-nees.png"
            sx={{
              objectFit: "cover",
              height: {
                xs: 30,
                sm: 50,
              },
            }}
          />
        </Stack>
      </Stack>
    </Container>
  );
};

export default TemplateAuth;
