import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Field, Form, Formik, FormikHelpers } from "formik";
import {
  Box,
  Button,
  MenuItem,
  Stack,
  Typography,
  Divider,
  Breadcrumbs,
  Link,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { KeyboardArrowRight, Home } from "@mui/icons-material";

import { statesOptions } from "@/data/states";

import { useToast } from "@/contexts/ToastContext";

import { ProfileEnum } from "@/enums/Profile";

import { TextInput } from "@/components/Inputs/TextInput";
import { SelectInput } from "@/components/Inputs/SelectInput";

import { useQueryUser } from "@/api/user/queries";
import { useMutationPutUser } from "@/api/user/mutations";

import { UserFormData } from "./types";

const YupSchema = Yup.object().shape({
  name: Yup.string().required("O nome é obrigatório"),
  email: Yup.string()
    .email("O e-mail deve ser válido")
    .required("O e-mail é obrigatório"),
  cellphone: Yup.string().required("O telefone é obrigatório"),
  cpf_cnpj: Yup.string().required("O CPF/CNPJ é obrigatório"),
  city: Yup.string().required("A cidade é obrigatória"),
  state: Yup.string().required("O estado é obrigatório"),
});

const Edit: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const params = useParams<{ idUser: string }>();

  const { data: user, isLoading: isLoadingUser } = useQueryUser({
    coUser: Number(params.idUser),
  });

  const { mutateAsync: mutatePutUser } = useMutationPutUser();

  const initialValues: UserFormData = {
    name: user?.dsName || "",
    email: user?.dsEmail || "",
    cellphone: user?.nuPhone || "",
    cpf_cnpj: user?.dsCpfCnpj || "",
    city: user?.dsCity || "",
    state: user?.dsState || "",
  };

  const handleSubmit = (
    values: UserFormData,
    { setSubmitting }: FormikHelpers<UserFormData>
  ) => {
    mutatePutUser({
      coUser: Number(params.idUser),
      dsName: values.name,
      dsEmail: values.email,
      nuPhone: values.cellphone,
      dsCpfCnpj: values.cpf_cnpj,
      dsCity: values.city,
      dsState: values.state,
      coProfile: ProfileEnum.researcher,
    })
      .then(() => {
        toast.show("Usuário editado com sucesso!", { severity: "success" });

        navigate("/gerenciamento/coordenador/usuarios");
      })
      .catch(() => {
        toast.show("Ocorreu um erro ao editar o usuário!", {
          severity: "error",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (isLoadingUser) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        height="100%"
        spacing={2}
      >
        <CircularProgress size="2rem" />
        <Typography>Carregando...</Typography>
      </Stack>
    );
  }

  return (
    <Stack direction="column" spacing={2}>
      <Box
        padding={2}
        borderRadius={4}
        bgcolor="white"
        sx={{ border: 1, borderColor: "divider" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Editar Usuário</Typography>

          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<KeyboardArrowRight fontSize="small" />}
          >
            <Link
              href="/"
              color="black"
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Home fontSize="small" />
            </Link>
            <Link
              href="/gerenciamento/coordenador/usuarios"
              color="black"
              underline="hover"
            >
              <Typography color="black">Usuários</Typography>
            </Link>
            <Typography color="grey">Editar</Typography>
          </Breadcrumbs>
        </Stack>
      </Box>

      <Box
        borderRadius={4}
        bgcolor="white"
        sx={{ border: 1, borderColor: "divider" }}
      >
        <Box borderRadius={4} overflow="hidden">
          <Stack bgcolor="white" spacing={3} sx={{ boxShadow: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              padding={3}
              paddingBottom={0}
            >
              <Typography variant="h5">Editar Usuário</Typography>
            </Stack>
            <Divider />
            <Stack spacing={3} padding={3} paddingTop={0}>
              <Formik
                initialValues={initialValues}
                validationSchema={YupSchema}
                onSubmit={handleSubmit}
              >
                {({ submitForm, isSubmitting }) => (
                  <Box component={Form} width="100%">
                    <Stack direction="column" spacing={3}>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(2, minmax(0px, 1fr))"
                        gap={2}
                      >
                        <Field
                          component={TextInput}
                          label="Nome de Usuário"
                          name="name"
                          size="small"
                          variant="outlined"
                        />
                        <Field
                          component={TextInput}
                          label="Email"
                          name="email"
                          size="small"
                          type="email"
                          variant="outlined"
                        />
                        <Field
                          component={TextInput}
                          label="Telefone"
                          name="cellphone"
                          size="small"
                          variant="outlined"
                        />
                        <Field
                          component={TextInput}
                          label="CPF/CNPJ"
                          name="cpf_cnpj"
                          size="small"
                          variant="outlined"
                        />
                        <Field
                          component={TextInput}
                          label="Cidade"
                          name="city"
                          size="small"
                          variant="outlined"
                        />
                        <Field
                          component={SelectInput}
                          label="Estado"
                          name="state"
                          size="small"
                          variant="outlined"
                          inputProps={{
                            MenuProps: { style: { maxHeight: 300 } },
                          }}
                        >
                          {statesOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Field>
                      </Box>

                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="flex-end"
                      >
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={() =>
                            navigate("/gerenciamento/coordenador/usuarios")
                          }
                          sx={{ paddingX: 4 }}
                        >
                          Voltar
                        </Button>
                        <LoadingButton
                          variant="contained"
                          disableElevation
                          loading={isSubmitting}
                          sx={{ paddingX: 4 }}
                          onClick={submitForm}
                        >
                          Editar
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  </Box>
                )}
              </Formik>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

export default Edit;
