import React from "react";
import {
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { ModalProps } from "./types";

const Modal: React.FC<ModalProps> = ({
  title,
  description,
  onClose,
  onDenied,
  onConfirmed,
  ...props
}) => {
  return (
    <Dialog onClose={onClose} maxWidth="sm" {...props}>
      <DialogTitle fontSize={18} sx={{ margin: 0, padding: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">{title}</Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={(theme) => ({
              color: theme.palette.grey[500],
            })}
          >
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Typography textAlign="center" gutterBottom>
          {description}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          color="error"
          variant="contained"
          size="small"
          disableElevation
          onClick={onDenied}
        >
          Não
        </Button>
        <Button
          variant="contained"
          size="small"
          disableElevation
          autoFocus
          onClick={onConfirmed}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
