import { AxiosError } from "axios";

import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import {
  postUser,
  putUser,
  putUserBlock,
  putUserUnblock,
  postUserNewPassword,
} from "./services";
import {
  postUserPayload,
  postUserResponse,
  putUserPayload,
  putUserResponse,
  putUserBlockPayload,
  putUserBlockResponse,
  putUserUnblockPayload,
  putUserUnblockResponse,
  postUserNewPasswordPayload,
  postUserNewPasswordResponse,
} from "./types";

export const useMutationPostUser = (
  options?: UseMutationOptions<postUserResponse, AxiosError, postUserPayload>
) => {
  return useMutation({ ...options, mutationFn: postUser });
};

export const useMutationPutUser = (
  options?: UseMutationOptions<putUserResponse, AxiosError, putUserPayload>
) => {
  return useMutation({ ...options, mutationFn: putUser });
};

export const useMutationPutUserBlock = (
  options?: UseMutationOptions<
    putUserBlockResponse,
    AxiosError,
    putUserBlockPayload
  >
) => {
  return useMutation({ ...options, mutationFn: putUserBlock });
};

export const useMutationPutUserUnblock = (
  options?: UseMutationOptions<
    putUserUnblockResponse,
    AxiosError,
    putUserUnblockPayload
  >
) => {
  return useMutation({ ...options, mutationFn: putUserUnblock });
};

export const useMutationPostUserNewPassword = (
  options?: UseMutationOptions<
    postUserNewPasswordResponse,
    AxiosError,
    postUserNewPasswordPayload
  >
) => {
  return useMutation({ ...options, mutationFn: postUserNewPassword });
};
