import React from "react";

import { getIn } from "formik";

import { Typography, FormControl, FormHelperText } from "@mui/material";
import MuiSelect, { SelectProps as MuiSelectProps } from "@mui/material/Select";

import { SelectInputProps } from "./types";

export function fieldToSelectInput({
  disabled,
  field: { onBlur: _onBlur, onChange: fieldOnChange, ...field },
  form: { isSubmitting, touched, errors, setFieldTouched, setFieldValue },
  onClose,
  ...props
}: Omit<
  SelectInputProps,
  "formControl" | "formHelperText" | "inputLabel"
>): MuiSelectProps & { formError: any } {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    disabled: disabled ?? isSubmitting,
    error: showError,
    formError: showError ? fieldError : undefined,
    onBlur: () => {},
    onChange: fieldOnChange ?? (() => {}),
    onClose:
      onClose ??
      (async (e: React.SyntheticEvent) => {
        const dataset = (e.target as any).dataset as DOMStringMap;
        if (dataset && dataset.value) {
          await setFieldValue(field.name, dataset.value, false);
        }
        setFieldTouched(field.name, true, true);
      }),
    ...field,
    ...props,
  };
}

export function SelectInput({
  formControl,
  inputLabel,
  formHelperText,
  ...selectProps
}: SelectInputProps) {
  const { label, error, formError, disabled, ...selectFieldProps } =
    fieldToSelectInput(selectProps);
  const { children: formHelperTextChildren, ...formHelperTextProps } =
    formHelperText || {};
  const shouldDisplayFormHelperText = error || formHelperTextChildren;

  return (
    <FormControl disabled={disabled} error={error} {...formControl}>
      {label && (
        <Typography
          variant="subtitle1"
          gutterBottom
          color={error ? "error" : "inherit"}
        >
          {label}
          {selectFieldProps.required && (
            <Typography display="inline" color="error" marginLeft={1}>
              *
            </Typography>
          )}
        </Typography>
      )}
      <MuiSelect {...selectFieldProps} />
      {shouldDisplayFormHelperText && (
        <FormHelperText {...formHelperTextProps}>
          {error ? formError : formHelperTextChildren}
        </FormHelperText>
      )}
    </FormControl>
  );
}
