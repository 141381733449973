import { AxiosError } from 'axios'
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getFileJson, getFilePDF } from './services'
import { getFileJsonResponse, getFilePDFResponse } from './types'

export const useQueryFileJson = (
  options?: Omit<UseQueryOptions<getFileJsonResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  const queryKey: QueryKey = ['file-json']
  const queryFn = getFileJson

  return useQuery<getFileJsonResponse, AxiosError>({
    queryKey,
    queryFn,
    ...options,
  })
}

export const useQueryFilePDF = (
  options?: Omit<UseQueryOptions<getFilePDFResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  const queryKey: QueryKey = ['file-pdf']
  const queryFn = getFilePDF

  return useQuery<getFilePDFResponse, AxiosError>({
    queryKey,
    queryFn,
    ...options,
  })
}