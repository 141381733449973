import React from "react";

import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from "@mui/material/Checkbox";

import { CheckboxInputProps } from "./types";

export function fieldToCheckboxInput({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting },
  onBlur,
  ...props
}: CheckboxInputProps): MuiCheckboxProps {
  const indeterminate = !Array.isArray(field.value) && field.value == null;

  return {
    disabled: disabled ?? isSubmitting,
    indeterminate,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    ...field,
    ...props,
  };
}

export function CheckboxInput(props: CheckboxInputProps) {
  return <MuiCheckbox {...fieldToCheckboxInput(props)} />;
}
