import React, { useContext, createContext, useState } from "react";

import { v4 as uuidv4 } from "uuid";

import { AlertColor } from "@mui/material";

import Toast from "@/common/components/Toast";
import { ToastMessage, ToastStyle } from "@/common/components/Toast/types";

export const ToastContext = createContext<
  | {
      addMessage: (message: ToastMessage) => void;
    }
  | undefined
>(undefined);

export const ToastProvider = ({
  children,
  ...props
}: { children: React.ReactNode } & ToastStyle) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addMessage = (message: ToastMessage) => {
    setMessages((arr) => [...arr, message]);
  };

  const removeMessage = (key: string) => {
    setMessages((arr) => arr.filter((m) => m.key !== key));
  };

  return (
    <ToastContext.Provider value={{ addMessage }}>
      {children}
      {messages.map((m, index) => (
        <Toast
          key={m.key}
          index={index}
          message={m}
          onExited={() => removeMessage(m.key)}
          {...props}
        />
      ))}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }

  const { addMessage } = context;

  const show = (message: string, options: { severity: AlertColor }) => {
    addMessage({ message, ...options, key: uuidv4() });
  };

  return {
    show,
    info(message: string) {
      show(message, { severity: "info" });
    },
    success(message: string) {
      show(message, { severity: "success" });
    },
    warning(message: string) {
      show(message, { severity: "warning" });
    },
    error(message: string) {
      show(message, { severity: "error" });
    },
  };
};
