import React from "react";

import { Stack, Box, Typography, Breadcrumbs, Link } from "@mui/material";
import { KeyboardArrowRight, Home } from "@mui/icons-material";

import Tabs from "@/common/components/Tabs";

import ByStatus from "./ByStatus";
import General from "./General";

const tabItems = [
  {
    id: "geral",
    label: "Geral por estado",
    render: () => <General />,
  },
  {
    id: "por_status",
    label: "Escolas por status",
    render: () => <ByStatus />,
  },
];

const Dashboard: React.FC = () => {
  return (
    <Stack direction="column" spacing={2}>
      <Box
        padding={2}
        borderRadius={4}
        bgcolor="white"
        sx={{ border: 1, borderColor: "divider" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Dashboard</Typography>

          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<KeyboardArrowRight fontSize="small" />}
          >
            <Link
              href="/"
              color="black"
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Home fontSize="small" />
            </Link>
            <Typography color="grey">Dashboard</Typography>
          </Breadcrumbs>
        </Stack>
      </Box>

      <Box
        padding={2}
        borderRadius={4}
        bgcolor="white"
        sx={{ border: 1, borderColor: "divider" }}
      >
        <Box borderRadius={4} overflow="hidden">
          <Tabs initialTab="geral" items={tabItems} />
        </Box>
      </Box>
    </Stack>
  );
};

export default Dashboard;
