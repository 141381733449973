import React from "react";

import {
  Stack,
  IconButton,
  Grid2,
  Divider,
  Typography,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Download } from "@mui/icons-material";

import InfoBox from "@/components/InfoBox";
import DataTable from "@/common/components/DataTable";

import { SchoolStatusEnum } from "@/enums/School";

import { statesLabels } from "@/data/states";

import { useQueryDashboardGeneralByState } from "@/api/dashboard/queries";
import { useQueryFormAnswerDownload } from "@/api/formAnswer/queries";
import { useQueryFeedbackDownload } from "@/api/feedback/queries";
import { getDashboardGeneralByStateResponse } from "@/api/dashboard/types";

const DownloadAnswer: React.FC<{ state: string }> = ({ state }) => {
  const {
    isLoading: isLoadingFormAnswerDownload,
    refetch: refetchFormAnswerDownload,
  } = useQueryFormAnswerDownload({ dsState: state }, { enabled: false });

  const handleDownload = () => {
    refetchFormAnswerDownload()
      .then((response) => {
        const { data } = response;

        if (!data) {
          console.error("Error downloading answers: no data");
          return;
        }

        const url = window.URL.createObjectURL(new Blob([data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `respostas-${state}.csv`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading answers", error);
      });
  };

  return isLoadingFormAnswerDownload ? (
    <CircularProgress size={24} />
  ) : (
    <IconButton color="primary" onClick={handleDownload}>
      <Download />
    </IconButton>
  );
};

const DownloadFeedback: React.FC<{ state: string }> = ({ state }) => {
  const {
    isLoading: isLoadingFeedbackDownload,
    refetch: refetchFeedbackDownload,
  } = useQueryFeedbackDownload({ dsState: state }, { enabled: false });

  const handleDownload = () => {
    refetchFeedbackDownload()
      .then((response) => {
        const { data } = response;

        if (!data) {
          console.error("Error downloading feedbacks: no data");
          return;
        }

        const url = window.URL.createObjectURL(new Blob([data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `feedbacks-${state}.csv`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading feedbacks", error);
      });
  };

  return isLoadingFeedbackDownload ? (
    <CircularProgress size={24} />
  ) : (
    <IconButton color="primary" onClick={handleDownload}>
      <Download />
    </IconButton>
  );
};

const General: React.FC = () => {
  const { data: dashboardGeneralByState, isLoading: isLoadingGeneral } =
    useQueryDashboardGeneralByState();

  const {
    isLoading: isLoadingFormAnswerDownload,
    refetch: refetchFormAnswerDownload,
  } = useQueryFormAnswerDownload({}, { enabled: false });
  const {
    isLoading: isLoadingFeedbackDownload,
    refetch: refetchFeedbackDownload,
  } = useQueryFeedbackDownload({}, { enabled: false });

  const headers = [
    {
      name: "Nome",
      key: "name",
    },
    {
      name: "Pendentes",
      key: "pending",
      align: "center",
    },
    {
      name: "Agendadas",
      key: "scheduled",
      align: "center",
    },
    {
      name: "Entrevistadas",
      key: "interviewed",
      align: "center",
    },
    {
      name: "Finalizadas",
      key: "finished",
      align: "center",
    },
    {
      name: "Respostas",
      key: "answers",
      type: "element",
      align: "center",
    },
    {
      name: "Feedbacks",
      key: "feedbacks",
      type: "element",
      align: "center",
    },
  ];

  const formatStatus = (
    item: getDashboardGeneralByStateResponse["byState"][string] | undefined,
    statusEnum: SchoolStatusEnum,
    defaultText = "0 (0%)"
  ) => {
    return item && item[statusEnum]
      ? `${item[statusEnum].count} (${item[statusEnum].percentage}%)`
      : defaultText;
  };

  const data = Object.keys(dashboardGeneralByState?.byState || {}).map(
    (state) => {
      const item = dashboardGeneralByState?.byState[state];

      return {
        state,
        name:
          statesLabels[state.toUpperCase() as keyof typeof statesLabels] ||
          "(Estado inválido)",
        pending: formatStatus(item, SchoolStatusEnum.pending),
        scheduled: formatStatus(item, SchoolStatusEnum.scheduled),
        interviewed: formatStatus(item, SchoolStatusEnum.visited),
        finished: formatStatus(item, SchoolStatusEnum.finished),
        answers: <DownloadAnswer state={state} />,
        feedbacks: <DownloadFeedback state={state} />,
      };
    }
  );

  const handleOnClickDownloadAllAnswers = () => {
    refetchFormAnswerDownload()
      .then((response) => {
        const { data } = response;

        if (!data) {
          console.error("Error downloading answers: no data");
          return;
        }

        const url = window.URL.createObjectURL(new Blob([data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "respostas.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading answers", error);
      });
  };

  const handleOnClickDownloadAllFeedbacks = () => {
    refetchFeedbackDownload()
      .then((response) => {
        const { data } = response;

        if (!data) {
          console.error("Error downloading feedbacks: no data");
          return;
        }

        const url = window.URL.createObjectURL(new Blob([data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "feedbacks.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading feedbacks", error);
      });
  };

  return (
    <Stack direction="column" spacing={3}>
      <Grid2 container spacing={4}>
        <Grid2 size={3}>
          <InfoBox
            title="Pendentes"
            value={
              dashboardGeneralByState?.general[SchoolStatusEnum.pending]
                ?.count || 0
            }
            percentage={
              dashboardGeneralByState?.general[SchoolStatusEnum.pending]
                ?.percentage || 0
            }
          />
        </Grid2>
        <Grid2 size={3}>
          <InfoBox
            title="Agendadas"
            value={
              dashboardGeneralByState?.general[SchoolStatusEnum.scheduled]
                ?.count || 0
            }
            percentage={
              dashboardGeneralByState?.general[SchoolStatusEnum.scheduled]
                ?.percentage || 0
            }
          />
        </Grid2>
        <Grid2 size={3}>
          <InfoBox
            title="Entrevistadas"
            value={
              dashboardGeneralByState?.general[SchoolStatusEnum.visited]
                ?.count || 0
            }
            percentage={
              dashboardGeneralByState?.general[SchoolStatusEnum.visited]
                ?.percentage || 0
            }
          />
        </Grid2>
        <Grid2 size={3}>
          <InfoBox
            title="Finalizadas"
            value={
              dashboardGeneralByState?.general[SchoolStatusEnum.finished]
                ?.count || 0
            }
            percentage={
              dashboardGeneralByState?.general[SchoolStatusEnum.finished]
                ?.percentage || 0
            }
          />
        </Grid2>
      </Grid2>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">Respostas</Typography>
        <Stack direction="row" spacing={2}>
          <LoadingButton
            variant="contained"
            disableElevation
            loading={isLoadingFormAnswerDownload}
            onClick={handleOnClickDownloadAllAnswers}
          >
            Baixar todas as respostas
          </LoadingButton>
          <LoadingButton
            variant="contained"
            disableElevation
            loading={isLoadingFeedbackDownload}
            onClick={handleOnClickDownloadAllFeedbacks}
          >
            Baixar todos os feedbacks
          </LoadingButton>
        </Stack>
      </Stack>
      <Stack direction="column">
        <Divider />
        <DataTable headers={headers} rowId="state" data={data} />
      </Stack>
    </Stack>
  );
};

export default General;
