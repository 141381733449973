import http from "@/api/http";

import {
  getUsersPayload,
  getUsersResponse,
  getUserPayload,
  getUserResponse,
  postUserPayload,
  postUserResponse,
  putUserPayload,
  putUserResponse,
  putUserBlockPayload,
  putUserBlockResponse,
  putUserUnblockPayload,
  putUserUnblockResponse,
  postUserNewPasswordPayload,
  postUserNewPasswordResponse,
} from "./types";

export const getUsers = async (
  payload: getUsersPayload
): Promise<getUsersResponse> => {
  const response = await http.get<getUsersResponse>("/user/", {
    params: payload,
  });

  return response.data;
};

export const getUser = async (
  payload: getUserPayload
): Promise<getUserResponse> => {
  const { coUser } = payload;

  const response = await http.get<getUserResponse>(`/user/${coUser}/`);

  return response.data;
};

export const postUser = async (
  payload: postUserPayload
): Promise<postUserResponse> => {
  const response = await http.post<postUserResponse>("/user/", payload);

  return response.data;
};

export const putUser = async (
  payload: putUserPayload
): Promise<putUserResponse> => {
  const { coUser, ...rest } = payload;

  const response = await http.put<putUserResponse>(`/user/${coUser}/`, rest);

  return response.data;
};

export const putUserBlock = async (
  payload: putUserBlockPayload
): Promise<putUserBlockResponse> => {
  const { coUser } = payload;

  const response = await http.put<putUserBlockResponse>(
    `/user/${coUser}/block/`
  );

  return response.data;
};

export const putUserUnblock = async (
  payload: putUserUnblockPayload
): Promise<putUserUnblockResponse> => {
  const { coUser } = payload;

  const response = await http.put<putUserUnblockResponse>(
    `/user/${coUser}/unblock/`
  );

  return response.data;
};

export const postUserNewPassword = async (
  payload: postUserNewPasswordPayload
): Promise<postUserNewPasswordResponse> => {
  const { coUser } = payload;

  const response = await http.post<postUserNewPasswordResponse>(
    `/user/${coUser}/new_password/`
  );

  return response.data;
};
