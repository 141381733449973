import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { postFormAnswer } from './services'
import {
  postFormAnswerPayload,
  postFormAnswerResponse,
} from './types'

export const useMutationPostFormAnswer = (
  options?: UseMutationOptions<postFormAnswerResponse, AxiosError, postFormAnswerPayload>,
) => {
  return useMutation({ ...options, mutationFn: postFormAnswer })
}
