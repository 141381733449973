import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { postAuthLogin, postAuthLogout } from './services'
import {
  postAuthLoginPayload,
  postAuthLoginResponse,
  postAuthLogoutResponse,
} from './types'

export const useMutationPostAuthLogin = (
  options?: UseMutationOptions<postAuthLoginResponse, AxiosError, postAuthLoginPayload>,
) => {
  return useMutation({ ...options, mutationFn: postAuthLogin })
}

export const useMutationPostAuthLogout = (
  options?: UseMutationOptions<postAuthLogoutResponse, AxiosError, void>,
) => {
  return useMutation({ ...options, mutationFn: postAuthLogout })
}