import {
  Form,
  Block,
  Question,
  CheckboxGroupQuestion,
} from '@/types/Form';
  
export const transformForm = (form: Form): Form => {
  let questionCounter = 0;

  const generateUniqueId = (baseId: string): string => {
    questionCounter += 1;
    return `${baseId.replace(/\.0$/, '')}.${questionCounter}`;
  };

  const transformSubform = (parentQuestion: CheckboxGroupQuestion): Question[] => {
    const newQuestions: Question[] = [];

    parentQuestion.options.forEach((option) => {
      parentQuestion.subform?.forEach((subQuestion) => {
        const newQuestion: Question = {
          ...subQuestion,
          id: generateUniqueId(parentQuestion.id),
          block: option,
          visibleIf: {
            questionId: parentQuestion.id,
            selectedOptionsInclude: [option],
          },
        };

        newQuestions.push(newQuestion);
      });
    });

    return newQuestions;
  };

  const traverseBlocks = (blocks: Block[]): Block[] => {
    return blocks.map((block) => {
      const newQuestions: Question[] = [];

      block.questions.forEach((question) => {
        const newQuestion: Question = {
          ...question,
          id: question.type === 'checkboxgroup' && question.subform ? `${question.id}.0` : question.id,
        };

        newQuestions.push(newQuestion);

        if (question.type === 'checkboxgroup') {
          const checkboxGroupQuestion = newQuestion as CheckboxGroupQuestion;
          if (checkboxGroupQuestion.subform) {
            const subformQuestions = transformSubform(checkboxGroupQuestion);
            newQuestions.push(...subformQuestions);
          }
        }
      });

      return {
        ...block,
        questions: newQuestions,
      };
    });
  };

  return {
    ...form,
    blocks: traverseBlocks(form.blocks),
  };
};
