import { AxiosError } from "axios";

import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { postSchools } from "./services";
import { postSchoolsPayload, postSchoolsResponse } from "./types";

export const useMutationPostSchools = (
  options?: UseMutationOptions<
    postSchoolsResponse,
    AxiosError,
    postSchoolsPayload
  >
) => {
  return useMutation({ ...options, mutationFn: postSchools });
};
