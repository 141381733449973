import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { Box, Divider } from "@mui/material";

import { useQuerySchoolResearcher } from "@/api/school/queries";
import { useQueryFileJson, useQueryFilePDF } from "@/api/file/queries";

import { useToast } from "@/contexts/ToastContext";
import { useModal } from "@/contexts/ModalContext";

import Header from "@/common/components/Header";
import LoadingState from "@/common/components/LoadingState";

import DownloadPDFButton from "@/modules/WithInterview/DownloadPDFButton";
import SchoolInfo from "@/modules/WithInterview/SchoolInfo";
import InterviewForm, {
  FormValues,
} from "@/modules/WithInterview/InterviewForm";

import { Form } from "@/types/Form";

import { transformForm } from "@/utils/form";

interface SavedData {
  [blockIndex: number]: FormValues;
}

const WithInterview: React.FC = () => {
  const toast = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const { openModal } = useModal();

  const schoolId = params.idSchool as string;
  const appointmentId = params.idAppointment as string;

  const [form, setForm] = useState<Form>();
  const [currentBlock, setCurrentBlock] = useState(0);

  const [savedData, setSavedData] = useState<SavedData>({});

  const { data: fileJson, isLoading: isLoadingFileJson } = useQueryFileJson();
  const {
    data: filePDF,
    isLoading: isLoadingFilePDF,
    refetch: refetchFilePDF,
  } = useQueryFilePDF({
    enabled: false,
  });
  const { data: schoolResearcher, isLoading: isLoadingSchoolResearcher } =
    useQuerySchoolResearcher({
      coSchool: Number(schoolId),
    });

  const getLocalStorageKey = (schoolId: string | undefined) => {
    return `interview_${schoolId}`;
  };

  useEffect(() => {
    if (fileJson) {
      const transformedForm = transformForm(fileJson);
      setForm(transformedForm);
    }
  }, [fileJson]);

  useEffect(() => {
    if (schoolId) {
      const saved = localStorage.getItem(getLocalStorageKey(schoolId));

      if (saved) {
        try {
          const parsed: SavedData = JSON.parse(saved);
          setSavedData(parsed);

          if (form) {
            const nextBlock = form.blocks.findIndex(
              (_, index) => !parsed[index]
            );
            setCurrentBlock(
              nextBlock !== -1 ? nextBlock : form.blocks.length - 1
            );
          }
        } catch (error) {
          toast.error("Erro ao carregar os dados salvos. Tente novamente.");
        }
      }
    }
  }, [schoolId, form]);

  const handleGoBack = () => {
    openModal({
      title: "Sair da entrevista",
      description: "Tem certeza que deseja sair da entrevista atual?",
      onDenied: () => {},
      onConfirmed: () => {
        navigate("/pesquisador/escolas");
      },
    });
  };

  if (isLoadingSchoolResearcher || isLoadingFileJson) {
    return <LoadingState />;
  }

  if (!form) {
    toast.error("Erro ao carregar o formulário. Tente novamente.");
    navigate("/pesquisador/escolas");
    return null;
  }

  return (
    <Box height="auto" padding={3}>
      <Header title="Realizar Entrevista" onGoBack={handleGoBack} />
      <Divider sx={{ width: "100%", marginY: 2 }} />
      <DownloadPDFButton
        filePDF={filePDF}
        isLoadingFilePDF={isLoadingFilePDF}
        refetchFilePDF={refetchFilePDF}
        schoolResearcher={schoolResearcher}
      />
      <Divider sx={{ width: "100%", marginY: 2 }} />
      <SchoolInfo schoolResearcher={schoolResearcher} />
      <Divider sx={{ width: "100%", marginY: 2 }} />
      <InterviewForm
        schoolId={schoolId}
        appointmentId={appointmentId}
        form={form}
        currentBlock={currentBlock}
        setCurrentBlock={setCurrentBlock}
        savedData={savedData}
        setSavedData={setSavedData}
      />
    </Box>
  );
};

export default WithInterview;
