import React from "react";

import { Stack, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import { HeaderProps } from "./types";

const Header: React.FC<HeaderProps> = ({ title, onGoBack }) => (
  <Stack
    position="relative"
    direction="row"
    alignItems="center"
    justifyContent="center"
  >
    <IconButton onClick={onGoBack} sx={{ position: "absolute", left: 0 }}>
      <ArrowBack fontSize="small" />
    </IconButton>
    <Typography variant="h5" fontWeight="500">
      {title}
    </Typography>
  </Stack>
);

export default Header;
