import React, { useEffect, useMemo } from "react";

import { FormControl, MenuItem, Divider } from "@mui/material";

import { Field } from "formik";

import { TextInput } from "@/components/Inputs/TextInput";
import { SelectInput } from "@/components/Inputs/SelectInput";
import { CheckboxGroupInput } from "@/components/Inputs/CheckboxGroupInput";

import { checkVisibility, getNestedValue } from "@/utils/question";

import { Question, Exclusivity } from "@/types/Form";

interface QuestionRendererProps {
  question: Question;
  values: Record<string, any>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const QuestionRenderer: React.FC<QuestionRendererProps> = ({
  question,
  values,
  setFieldValue,
}) => {
  const resetFieldValue = (
    question: Question,
    setFieldValue: QuestionRendererProps["setFieldValue"]
  ) => {
    const resetValue = question.type === "checkboxgroup" ? [] : "";
    setFieldValue(question.id, resetValue);
  };

  const isVisible = useMemo(
    () => checkVisibility(question, values),
    [question, values]
  );

  useEffect(() => {
    if (!isVisible) {
      resetFieldValue(question, setFieldValue);
    }
  }, [isVisible]);

  useEffect(() => {
    if (
      question.exclusivity &&
      Array.isArray(getNestedValue(question.id, values))
    ) {
      const { exclusiveOption } = question.exclusivity as Exclusivity;
      const currentValues: any[] = getNestedValue(question.id, values) || [];

      if (currentValues.length > 1 && currentValues.includes(exclusiveOption)) {
        setFieldValue(question.id, [exclusiveOption]);
      }
    }
  }, [values]);

  if (!isVisible) return null;

  const commonProps = {
    name: question.id,
    label: `${question.id.replace(/\.0$/, "")} - ${question.text}`,
    required: question.required,
    fullWidth: true,
  };

  const renderField = () => {
    switch (question.type) {
      case "text":
        return (
          <Field
            key={question.id}
            component={TextInput}
            type="text"
            {...commonProps}
            {...(question.multiline ? { multiline: true, rows: 4 } : {})}
          />
        );
      case "number":
        return (
          <Field
            key={question.id}
            component={TextInput}
            type="number"
            {...commonProps}
          />
        );
      case "select":
        return (
          <FormControl key={question.id} fullWidth>
            <Field component={SelectInput} {...commonProps}>
              <MenuItem value="">Nenhum(a)</MenuItem>
              <Divider />
              {question.options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        );
      case "checkboxgroup":
        return (
          <Field
            key={question.id}
            component={CheckboxGroupInput}
            name={question.id}
            title={commonProps.label}
            options={question.options.map((option) => ({
              label: option,
              value: option,
            }))}
            required={question.required}
          />
        );
      case "checkbox":
        return (
          <Field
            key={question.id}
            component={CheckboxGroupInput}
            name={question.id}
            title={commonProps.label}
            options={[
              { label: "Sim", value: "true" },
              { label: "Não", value: "false" },
            ]}
            required={question.required}
            singleSelection
            row
          />
        );
      case "scale":
        const scaleOptions = Array.from(
          { length: question.scale.max - question.scale.min + 1 },
          (_, i) => (i + question.scale.min).toString()
        );

        return (
          <Field
            key={question.id}
            component={CheckboxGroupInput}
            name={question.id}
            title={commonProps.label}
            options={scaleOptions.map((option) => ({
              label: option,
              value: option,
            }))}
            required={question.required}
            singleSelection
            row
          />
        );
      default:
        return null;
    }
  };

  return renderField();
};

export default QuestionRenderer;
