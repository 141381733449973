import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { Field, Form, Formik, FormikHelpers } from "formik";

import {
  Box,
  Stack,
  Grid2,
  Typography,
  Button,
  IconButton,
  MenuItem,
  FormControl,
  Divider,
  Breadcrumbs,
  Link,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  Search,
  KeyboardArrowRight,
  Home,
  Add,
  Close,
  Delete,
} from "@mui/icons-material";

import { SelectInput } from "@/components/Inputs/SelectInput";
import { TextInput } from "@/components/Inputs/TextInput";

import { useToast } from "@/contexts/ToastContext";

import InfoBox from "@/components/InfoBox";
import DataTable from "@/common/components/DataTable";

import { statesOptions, statesLabels } from "@/data/states";
import { SchoolStatusEnum } from "@/enums/School";

import { useMutationPostSchools } from "@/api/school/mutations";
import { useQuerySchoolCoordinator } from "@/api/school/queries";

interface SchoolsFormData {
  search: string;
  state: string;
  status: string;
}

const initialValues: SchoolsFormData = {
  search: "",
  state: "",
  status: "",
};

const validationSchema = Yup.object({
  search: Yup.string(),
  state: Yup.string(),
  status: Yup.string(),
});

interface CSVUploadModalProps {
  open: boolean;
  onClose: () => void;
}

const CSVUploadModal: React.FC<CSVUploadModalProps> = ({ open, onClose }) => {
  const toast = useToast();

  const { mutateAsync: mutatePostSchools } = useMutationPostSchools();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedFile(file);
      event.target.value = "";
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement("a");
    link.href = "/assets/csv/schools.csv";
    link.download = "modelo_escola.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmit = () => {
    if (selectedFile) {
      mutatePostSchools({ csv: selectedFile })
        .then(() => {
          toast.show("Escolas importadas com sucesso!", {
            severity: "success",
          });
        })
        .catch(() => {
          toast.show("Ocorreu um erro ao importar as escolas!", {
            severity: "error",
          });
        })
        .finally(() => {
          setSelectedFile(null);
          onClose();
        });
    } else {
      toast.show("Selecione um arquivo CSV para importar!", {
        severity: "error",
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle fontSize={18} sx={{ margin: 0, padding: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Cadastrar Escolas</Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={(theme) => ({
              color: theme.palette.grey[500],
            })}
          >
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          <input
            accept=".csv"
            style={{ display: "none" }}
            id="csv-upload"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="csv-upload">
            <Button
              variant="contained"
              component="span"
              fullWidth
              disableElevation
            >
              Adicionar arquivo CSV com as escolas
            </Button>
          </label>
          {selectedFile && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              border={1}
              borderColor="grey.300"
              borderRadius={4}
              paddingX={2}
              paddingY={1}
              marginTop={2}
            >
              <Typography variant="body1">{selectedFile.name}</Typography>
              <IconButton color="error" onClick={handleRemoveFile}>
                <Delete />
              </IconButton>
            </Stack>
          )}
          <Button variant="outlined" onClick={handleDownloadTemplate}>
            Baixar modelo de CSV para importação
          </Button>
          <Typography variant="body2" color="textSecondary" align="justify">
            Atenção: apenas as escolas que não estiverem presentes no banco de
            dados serão adicionadas. Escolas já presentes no banco com número de
            telefone diferente do informado no CSV acrescentarão o número à
            lista de telefones e terão os dados atualizados de acordo com os
            novos dados informados.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          color="error"
          variant="contained"
          size="small"
          disableElevation
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          size="small"
          disableElevation
          onClick={handleSubmit}
          disabled={!selectedFile}
        >
          Cadastrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const View = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const [search, setSearch] = useState("");
  const [state, setState] = useState("");
  const [status, setStatus] = useState("");

  const { data: schoolCoordinator, isLoading: isLoadingSchoolCoordinator } =
    useQuerySchoolCoordinator({
      dsName: search,
      dsState: state,
      stResearchStatus: status,
    });

  const headers = [
    { name: "Nome", key: "name" },
    { name: "Cidade", key: "city" },
    { name: "Estado", key: "state" },
    { name: "Status", key: "status" },
    { name: "Substituta", key: "substitute" },
    { name: "Usuário Vinculado", key: "vinculated_user" },
  ];

  const getStatusName = (status?: number) => {
    if (!status) {
      return "Status inválido";
    }

    switch (status) {
      case SchoolStatusEnum.pending:
        return "Pendente";
      case SchoolStatusEnum.scheduled:
        return "Agendada";
      case SchoolStatusEnum.replaced:
        return "Substituída";
      case SchoolStatusEnum.visited:
        return "Entrevistada";
      case SchoolStatusEnum.finished:
        return "Finalizada";
      default:
        return "Status inválido";
    }
  };

  const schools =
    schoolCoordinator?.schools.map(
      (school) =>
        ({
          id: school.coSchool,
          name: school.dsName,
          city: school.dsCity,
          state: school.dsState
            ? statesLabels[
                school.dsState.toUpperCase() as keyof typeof statesLabels
              ] || "(Estado inválido)"
            : "(Sem estado)",
          status: getStatusName(school.stResearchStatus),
          substitute: school.substituteSchool ? "Sim" : "Não",
          vinculated_user: "Usuário vinculado",
        } as {
          id: number;
          name: string;
          city: string;
          state: string;
          status: string;
          substitute: string;
          vinculated_user: string;
        })
    ) || [];

  const createCoordinator = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => setModalOpen(false);

  const handleOnSubmit = (
    values: SchoolsFormData,
    { setSubmitting }: FormikHelpers<SchoolsFormData>
  ) => {
    const { search, state, status } = values;

    setSearch(search);
    setState(state);
    setStatus(status);

    setSubmitting(false);
  };

  return (
    <Stack direction="column" spacing={2}>
      <Box
        padding={2}
        borderRadius={4}
        bgcolor="white"
        sx={{ border: 1, borderColor: "divider" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Escolas</Typography>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<KeyboardArrowRight fontSize="small" />}
          >
            <Link
              href="/"
              color="black"
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Home fontSize="small" />
            </Link>
            <Link
              href="/gerenciamento/coordenador/escolas"
              color="black"
              underline="hover"
            >
              <Typography color="black">Escolas</Typography>
            </Link>
            <Typography color="grey">Listagem</Typography>
          </Breadcrumbs>
        </Stack>
      </Box>

      <Box
        borderRadius={4}
        bgcolor="white"
        sx={{ border: 1, borderColor: "divider" }}
      >
        <Box borderRadius={4} overflow="hidden">
          <Stack bgcolor="white" spacing={3} sx={{ boxShadow: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              padding={3}
              paddingBottom={0}
            >
              <Typography variant="h5">Listagem de Escolas</Typography>
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<Add />}
                  onClick={createCoordinator}
                  disableElevation
                >
                  Cadastrar Escolas
                </Button>
              </Box>
            </Stack>
            <Divider />
            <Stack spacing={3} padding={3} paddingTop={0}>
              <Grid2 container spacing={4}>
                <Grid2 size={3}>
                  <InfoBox
                    title="Pendentes"
                    value={
                      schoolCoordinator?.general[SchoolStatusEnum.pending]
                        ?.count || 0
                    }
                    percentage={
                      schoolCoordinator?.general[SchoolStatusEnum.pending]
                        ?.percentage || 0
                    }
                  />
                </Grid2>
                <Grid2 size={3}>
                  <InfoBox
                    title="Agendadas"
                    value={
                      schoolCoordinator?.general[SchoolStatusEnum.scheduled]
                        ?.count || 0
                    }
                    percentage={
                      schoolCoordinator?.general[SchoolStatusEnum.scheduled]
                        ?.percentage || 0
                    }
                  />
                </Grid2>
                <Grid2 size={3}>
                  <InfoBox
                    title="Entrevistadas"
                    value={
                      schoolCoordinator?.general[SchoolStatusEnum.visited]
                        ?.count || 0
                    }
                    percentage={
                      schoolCoordinator?.general[SchoolStatusEnum.visited]
                        ?.percentage || 0
                    }
                  />
                </Grid2>
                <Grid2 size={3}>
                  <InfoBox
                    title="Finalizadas"
                    value={
                      schoolCoordinator?.general[SchoolStatusEnum.finished]
                        ?.count || 0
                    }
                    percentage={
                      schoolCoordinator?.general[SchoolStatusEnum.finished]
                        ?.percentage || 0
                    }
                  />
                </Grid2>
              </Grid2>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleOnSubmit}
              >
                {({ setFieldValue, isSubmitting }) => (
                  <Box component={Form} width="100%">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Stack direction="row" spacing={2}>
                        <FormControl sx={{ width: 400 }}>
                          <Field
                            component={TextInput}
                            name="search"
                            size="small"
                            placeholder="Pesquisar por nome"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Search />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                        <FormControl sx={{ width: 200 }}>
                          <Field
                            component={SelectInput}
                            name="state"
                            size="small"
                            variant="outlined"
                            displayEmpty
                          >
                            <MenuItem value="">Selecione um estado</MenuItem>
                            {statesOptions.map((state) => (
                              <MenuItem key={state.value} value={state.value}>
                                {state.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                        <FormControl sx={{ width: 200 }}>
                          <Field
                            component={SelectInput}
                            name="status"
                            size="small"
                            variant="outlined"
                            displayEmpty
                          >
                            <MenuItem value="">Selecione um status</MenuItem>
                            <MenuItem value={SchoolStatusEnum.pending}>
                              Pendente
                            </MenuItem>
                            <MenuItem value={SchoolStatusEnum.scheduled}>
                              Agendada
                            </MenuItem>
                            <MenuItem value={SchoolStatusEnum.replaced}>
                              Substituída
                            </MenuItem>
                            <MenuItem value={SchoolStatusEnum.visited}>
                              Entrevistada
                            </MenuItem>
                            <MenuItem value={SchoolStatusEnum.finished}>
                              Finalizada
                            </MenuItem>
                          </Field>
                        </FormControl>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={() => {
                            setFieldValue("state", "");
                            setFieldValue("search", "");
                            setFieldValue("status", "");
                            setSearch("");
                            setState("");
                            setStatus("");
                          }}
                          sx={{ paddingX: 4 }}
                        >
                          Limpar
                        </Button>
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          disableElevation
                          loading={isSubmitting}
                          sx={{ paddingX: 4 }}
                        >
                          Filtrar
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  </Box>
                )}
              </Formik>
              <Stack direction="column">
                <Divider />
                <DataTable headers={headers} data={schools} />
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>

      <CSVUploadModal open={modalOpen} onClose={handleCloseModal} />
    </Stack>
  );
};

export default View;
