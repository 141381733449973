import React from "react";

import { InputMask } from "@react-input/mask";

import { MaskedInputProps } from "./types";

const MaskedInput = React.forwardRef<HTMLInputElement, MaskedInputProps>(
  (props, forwardedRef) => {
    return <InputMask ref={forwardedRef} {...props} />;
  }
);

export default MaskedInput;
