import http from '@/api/http'

import { postAppointmentPayload, postAppointmentResponse, postAppointmentReplacePayload, postAppointmentReplaceResponse, postAppointmentCancelPayload, postAppointmentCancelResponse } from './types'

export const postAppointment = async (payload: postAppointmentPayload): Promise<postAppointmentResponse> => {
  const response = await http.post<postAppointmentResponse>('/appointment/', payload)

  return response.data
}

export const postAppointmentReplace = async (payload: postAppointmentReplacePayload): Promise<postAppointmentReplaceResponse> => {
  const response = await http.post<postAppointmentReplaceResponse>('/appointment/replace_school/', payload)

  return response.data
}

export const postAppointmentCancel = async (payload: postAppointmentCancelPayload): Promise<postAppointmentCancelResponse> => {
  const { coAppointment } = payload

  const response = await http.post<postAppointmentCancelResponse>(`/appointment/${coAppointment}/cancel/`)

  return response.data
}