import http from '@/api/http'
import { getFileJsonResponse, getFilePDFResponse } from './types'

export const getFileJson = async (): Promise<getFileJsonResponse> => {
  const response = await http.get<getFileJsonResponse>('/file/json/')

  return response.data
}

export const getFilePDF = async (): Promise<getFilePDFResponse> => {
  const response = await http.get<getFilePDFResponse>('/file/pdf/', {
    responseType: 'blob',
  })

  return response.data
}