import React from "react";

import { Button, CircularProgress } from "@mui/material";
import { Print } from "@mui/icons-material";

import { useToast } from "@/contexts/ToastContext";

import { SchoolResearcher } from "@/types/School";

interface DownloadPDFButtonProps {
  filePDF: Blob | undefined;
  isLoadingFilePDF: boolean;
  refetchFilePDF: () => Promise<any>;
  schoolResearcher: SchoolResearcher | undefined;
}

const DownloadPDFButton: React.FC<DownloadPDFButtonProps> = ({
  filePDF,
  isLoadingFilePDF,
  refetchFilePDF,
  schoolResearcher,
}) => {
  const toast = useToast();

  const handleDownloadPDF = async () => {
    try {
      let data = filePDF;

      if (!data) {
        const response = await refetchFilePDF();
        data = response.data;
      }

      if (!data) {
        toast.error("Erro ao baixar o PDF! Tente novamente mais tarde.");
        return;
      }

      const blob = new Blob([data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${schoolResearcher?.dsName}_questionario.pdf`;

      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      window.URL.revokeObjectURL(url);

      toast.success("Download realizado com sucesso!");
    } catch (error) {
      toast.error("Erro ao baixar o PDF! Tente novamente mais tarde.");
    }
  };

  return (
    <Button
      variant="outlined"
      startIcon={<Print />}
      endIcon={isLoadingFilePDF ? <CircularProgress size="1rem" /> : null}
      disabled={isLoadingFilePDF}
      onClick={handleDownloadPDF}
      fullWidth
    >
      Baixar PDF com o questionário
    </Button>
  );
};

export default DownloadPDFButton;
