import React from "react";

import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";

import AdminTemplate from "@/templates/AdminTemplate";
import UserTemplate from "@/templates/UserTemplate";
import AuthTemplate from "@/templates/AuthTemplate";

import SignIn from "@/pages/auth/SignIn";

import AdminManagerDashboard from "@/pages/admin/manager/dashboard";
import AdminManagerViewUsers from "@/pages/admin/manager/users/View";
import AdminManagerCreateUser from "@/pages/admin/manager/users/Create";
import AdminManagerEditUser from "@/pages/admin/manager/users/Edit";

import AdminCoordinatorViewUsers from "@/pages/admin/coordinator/users/View";
import AdminCoordinatorCreateUser from "@/pages/admin/coordinator/users/Create";
import AdminCoordinatorEditUsers from "@/pages/admin/coordinator/users/Edit";
import AdminCoordinatorViewSchools from "@/pages/admin/coordinator/schools/View";

import UserViewSchools from "@/pages/user/schools/View";
import UserReplaceVisit from "@/pages/user/visit/Replace";
import UserScheduleVisit from "@/pages/user/visit/Schedule";
import UserScheduleFeedback from "@/pages/user/visit/Feedback";
import UserRegisterVisitWithInterview from "@/pages/user/visit/register/WithInterview";
import UserRegisterVisitWithoutInterview from "@/pages/user/visit/register/WithoutInterview";

import PrivateRoute from "@/guards/PrivateRoute";

const App: React.FC = () => (
  <Router>
    <Routes>
      {/* Authentication Routes */}
      <Route path="/acesso" element={<AuthTemplate />}>
        <Route path="entrar" element={<SignIn />} />
        <Route index path="*" element={<Navigate to="entrar" />} />
      </Route>

      {/* Protected Routes */}
      <Route element={<PrivateRoute />}>
        {/* Admin Routes */}
        <Route path="/gerenciamento" element={<AdminTemplate />}>
          <Route path="gestor">
            <Route path="dashboard" element={<AdminManagerDashboard />} />
            <Route path="usuarios" element={<AdminManagerViewUsers />} />
            <Route path="usuario/criar" element={<AdminManagerCreateUser />} />
            <Route
              path="usuario/editar/:idUser"
              element={<AdminManagerEditUser />}
            />
            <Route index path="*" element={<Navigate to="dashboard" />} />
          </Route>

          <Route path="coordenador">
            <Route path="usuarios" element={<AdminCoordinatorViewUsers />} />
            <Route
              path="usuario/criar"
              element={<AdminCoordinatorCreateUser />}
            />
            <Route
              path="usuario/editar/:idUser"
              element={<AdminCoordinatorEditUsers />}
            />
            <Route path="escolas" element={<AdminCoordinatorViewSchools />} />
            <Route index path="*" element={<Navigate to="usuarios" />} />
          </Route>
        </Route>

        {/* User Routes */}
        <Route path="/pesquisador" element={<UserTemplate />}>
          <Route path="escolas" element={<UserViewSchools />} />

          <Route path="escola/:idSchool/agendamento">
            <Route path="criar" element={<UserScheduleVisit />} />
            <Route
              path=":idAppointment/substituir"
              element={<UserReplaceVisit />}
            />

            <Route path=":idAppointment/visita">
              <Route
                path="registrar/entrevista"
                element={<UserRegisterVisitWithInterview />}
              />
              <Route
                path="registrar/sem-entrevista"
                element={<UserRegisterVisitWithoutInterview />}
              />
            </Route>
          </Route>

          <Route
            path="escola/:idSchool/visita/:idVisit/parecer"
            element={<UserScheduleFeedback />}
          />

          <Route
            index
            path="*"
            element={<Navigate to="/pesquisador/escolas" />}
          />
        </Route>
      </Route>

      {/* Global Fallback */}
      <Route index path="*" element={<Navigate to="/acesso/entrar" />} />
    </Routes>
  </Router>
);

export default App;
