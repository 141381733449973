import http from "@/api/http";

import { PaginatedResponse } from "@/types/Pagination";
import {
  postSchoolsPayload,
  postSchoolsResponse,
  getSchoolsResearcherPayload,
  getSchoolsResearcherResponse,
  getSchoolCoordinatorPayload,
  getSchoolCoordinatorResponse,
  getSchoolResearcherPayload,
  getSchoolResearcherResponse,
} from "./types";

export const postSchools = async (
  payload: postSchoolsPayload
): Promise<postSchoolsResponse> => {
  const { csv } = payload;

  const formData = new FormData();
  formData.append("file", csv);

  const response = await http.post<postSchoolsResponse>("/school/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

export const getSchoolsResearcher = async (
  payload: getSchoolsResearcherPayload
): Promise<getSchoolsResearcherResponse> => {
  const response = await http.get<getSchoolsResearcherResponse>(
    "/school/researcher/",
    {
      params: payload,
    }
  );

  return response.data;
};

export const getSchoolCoordinator = async (
  payload: getSchoolCoordinatorPayload
): Promise<getSchoolCoordinatorResponse> => {
  const response = await http.get<getSchoolCoordinatorResponse>(
    "/school/coordinator/",
    {
      params: payload,
    }
  );

  return response.data;
};

export const getSchoolResearcher = async (
  payload: getSchoolResearcherPayload
): Promise<getSchoolResearcherResponse> => {
  const { coSchool } = payload;

  const response = await http.get<
    PaginatedResponse<getSchoolResearcherResponse>
  >("/school/researcher/", {
    params: { coSchool },
  });

  return response.data.results[0];
};
