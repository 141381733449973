import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { postAppointment, postAppointmentReplace, postAppointmentCancel } from './services'
import {
  postAppointmentPayload,
  postAppointmentResponse,
  postAppointmentReplacePayload,
  postAppointmentReplaceResponse,
  postAppointmentCancelPayload,
  postAppointmentCancelResponse,
} from './types'

export const useMutationPostAppointment = (
  options?: UseMutationOptions<postAppointmentResponse, AxiosError, postAppointmentPayload>,
) => {
  return useMutation({ ...options, mutationFn: postAppointment })
}

export const useMutationPostAppointmentReplace = (
  options?: UseMutationOptions<postAppointmentReplaceResponse, AxiosError, postAppointmentReplacePayload>,
) => {
  return useMutation({ ...options, mutationFn: postAppointmentReplace })
}

export const useMutationPostAppointmentCancel = (
  options?: UseMutationOptions<postAppointmentCancelResponse, AxiosError, postAppointmentCancelPayload>,
) => {
  return useMutation({ ...options, mutationFn: postAppointmentCancel })
}