import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { postVisit } from './services'
import {
  postVisitPayload,
  postVisitResponse,
} from './types'

export const useMutationPostVisit = (
  options?: UseMutationOptions<postVisitResponse, AxiosError, postVisitPayload>,
) => {
  return useMutation({ ...options, mutationFn: postVisit })
}
