import React, { useContext, createContext, useState } from "react";

import Modal from "@/common/components/Modal";

const ModalContext = createContext({
  openModal: (options: {
    title?: string;
    description?: string;
    onConfirmed?: () => void;
    onDenied?: () => void;
  }) => {},
  closeModal: () => {},
});

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [modalTitle, setModalTitle] = useState<string | undefined>();
  const [modalDescription, setModalDescription] = useState<
    string | undefined
  >();

  const [onConfirmed, setOnConfirmed] = useState<(() => void) | undefined>();
  const [onDenied, setOnDenied] = useState<(() => void) | undefined>();

  const openModal = (options: {
    title?: string;
    description?: string;
    onConfirmed?: () => void;
    onDenied?: () => void;
  }) => {
    const { title, description, onConfirmed, onDenied } = options;
    setModalTitle(title);
    setModalDescription(description);
    setOnConfirmed(() => onConfirmed);
    setOnDenied(() => onDenied);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalTitle(undefined);
    setModalDescription(undefined);
    setOnConfirmed(undefined);
    setOnDenied(undefined);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <Modal
        open={isOpen}
        title={modalTitle}
        description={modalDescription}
        onClose={closeModal}
        onDenied={() => {
          if (onDenied) onDenied();
          closeModal();
        }}
        onConfirmed={() => {
          if (onConfirmed) onConfirmed();
          closeModal();
        }}
      />
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }

  return context;
};
