import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { Field, Form, Formik, FormikHelpers } from "formik";

import {
  Stack,
  Box,
  Card,
  Divider,
  Button,
  Typography,
  InputAdornment,
  FormControl,
  MenuItem,
  CircularProgress,
  Fab,
} from "@mui/material";
import { Search, ArrowUpward } from "@mui/icons-material";

import { useQuerySchoolsResearcher } from "@/api/school/queries";
import { useMutationPostAppointmentCancel } from "@/api/appointment/mutations";

import { TextInput } from "@/components/Inputs/TextInput";
import { SelectInput } from "@/components/Inputs/SelectInput";

import { useToast } from "@/contexts/ToastContext";
import { useModal } from "@/contexts/ModalContext";

import { SchoolResearcher } from "@/types/School";
import { SchoolStatusEnum } from "@/enums/School";

export interface SchoolFormData {
  search: string;
  status: string;
}

const initialValues: SchoolFormData = {
  search: "",
  status: "",
};

const YupSchema = Yup.object().shape({
  search: Yup.string(),
  status: Yup.string(),
});

const View: React.FC = () => {
  const navigate = useNavigate();

  const toast = useToast();
  const { openModal } = useModal();

  const refView = React.useRef<HTMLDivElement>(null);

  const [search, setSearch] = useState<string>();
  const [status, setStatus] = useState<number>();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [hasMore, setHasMore] = useState(true);

  const [allSchools, setAllSchools] = useState<SchoolResearcher[]>([]);

  const [scrollTrigger, setScrollTrigger] = useState(false);

  const {
    data: schools,
    isLoading: isLoadingSchools,
    refetch: refetchSchools,
  } = useQuerySchoolsResearcher({
    page,
    pageSize,
    dsName: search,
    stResearchStatus: status,
  });

  const { mutateAsync: mutatePostAppointmentCancel } =
    useMutationPostAppointmentCancel();

  const getSchoolStatusColor = (status: number) => {
    switch (SchoolStatusEnum[status]) {
      case "pending":
        return "warning.main";
      case "scheduled":
        return "primary.main";
      case "visited":
        return "success.main";
      default:
        return "error.main";
    }
  };

  const getSchoolStatus = (status: number) => {
    switch (SchoolStatusEnum[status]) {
      case "pending":
        return "Pendente";
      case "scheduled":
        return "Agendada";
      case "visited":
        return "Entrevistada";
      default:
        return "Desconhecido";
    }
  };

  const parseDate = (date: string) => {
    return new Date(date).toLocaleDateString();
  };

  const parseTime = (time: string) => {
    const timeParts = time.split(":");

    return `${timeParts[0]}:${timeParts[1]}`;
  };

  const reloadSchools = () => {
    setPage(1);
    setHasMore(true);

    refetchSchools();
  };

  const handleNextSchools = () => {
    setPage((prev) => prev + 1);
  };

  const handleScroll = (
    event: Event,
    { toleranceGoTop = 100, tolerance = 30 } = {}
  ) => {
    if (!refView.current) return;

    const { scrollTop, clientHeight, scrollHeight } = refView.current;

    if (scrollTop > toleranceGoTop) {
      setScrollTrigger(true);
    } else {
      setScrollTrigger(false);
    }

    if (scrollTop + clientHeight + tolerance >= scrollHeight) {
      handleNextSchools();
    }
  };

  const handleScrollTop = () => {
    if (!refView.current) return;

    refView.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScrollOverflow = () => {
    if (!refView.current) return;

    const { clientHeight, scrollHeight } = refView.current;

    if (clientHeight === scrollHeight) {
      handleNextSchools();
    }
  };

  const handleOnSubmit = (
    values: SchoolFormData,
    { setSubmitting }: FormikHelpers<SchoolFormData>
  ) => {
    const { search, status } = values;

    setSearch(search ? search : undefined);
    setStatus(status ? Number(status) : undefined);

    reloadSchools();

    setSubmitting(false);
  };

  const handleOnClickSchedule = (school: SchoolResearcher) => {
    navigate(`/pesquisador/escola/${school.coSchool}/agendamento/criar`);
  };

  const handleOnClickFeedback = (school: SchoolResearcher) => {
    navigate(
      `/pesquisador/escola/${school.coSchool}/visita/${school.visit?.coVisit}/parecer`
    );
  };

  const handleOnClickReplace = (school: SchoolResearcher) => {
    navigate(
      `/pesquisador/escola/${school.coSchool}/agendamento/${school.appointment?.coAppointment}/substituir`
    );
  };

  const handleOnClickCancel = (school: SchoolResearcher) => {
    openModal({
      title: school.dsName,
      description: "Você realmente deseja cancelar o agendamento?",
      onConfirmed: () => {
        mutatePostAppointmentCancel({
          coAppointment: Number(school.appointment?.coAppointment),
        })
          .then(() => {
            toast.success("Agendamento cancelado com sucesso!");
          })
          .catch(() => {
            toast.error("Não foi possível cancelar o agendamento!");
          })
          .finally(() => {
            reloadSchools();
          });
      },
    });
  };

  const handleOnClickVisit = (school: SchoolResearcher) => {
    openModal({
      title: school.dsName,
      description: "Foi possível realizar a entrevista durante a visita?",
      onDenied: () => {
        navigate(
          `/pesquisador/escola/${school.coSchool}/agendamento/${school.appointment?.coAppointment}/visita/registrar/sem-entrevista`
        );
      },
      onConfirmed: () => {
        navigate(
          `/pesquisador/escola/${school.coSchool}/agendamento/${school.appointment?.coAppointment}/visita/registrar/entrevista`
        );
      },
    });
  };

  useEffect(() => {
    if (!refView.current) return;

    if (hasMore) {
      refView.current.addEventListener("scroll", handleScroll);
    } else {
      refView.current.removeEventListener("scroll", handleScroll);
    }

    return () => {
      refView.current?.removeEventListener("scroll", handleScroll);
    };
  }, [hasMore]);

  useEffect(() => {
    if (!schools) return;

    setAllSchools((prev) => {
      const resultsMap = new Map();

      (prev ?? []).forEach((item) => {
        resultsMap.set(item.coSchool, item);
      });

      schools.results.forEach((item) => {
        resultsMap.set(item.coSchool, item);
      });

      const mergedResults = Array.from(resultsMap.values());

      if (schools.pagination.currentPage === 1) {
        return schools.results;
      }

      return mergedResults;
    });

    if (schools.pagination.currentPage === schools.pagination.totalPages) {
      setHasMore(false);
    } else {
      setHasMore(true);
      handleScrollOverflow();
    }
  }, [schools]);

  return (
    <Box ref={refView} height="auto" position="relative" padding={3}>
      {scrollTrigger ? (
        <Box position="sticky" top={0} zIndex={10}>
          <Fab
            size="medium"
            color="primary"
            aria-label="Voltar para o comeco da página"
            onClick={handleScrollTop}
          >
            <ArrowUpward />
          </Fab>
        </Box>
      ) : null}

      <Typography variant="h5" fontWeight="500" textAlign="center">
        Lista de Escolas
      </Typography>
      <Divider sx={{ width: "100%", marginY: 2 }} />
      <Formik
        initialValues={initialValues}
        validationSchema={YupSchema}
        onSubmit={handleOnSubmit}
      >
        {({ submitForm, setFieldValue, isSubmitting }) => (
          <Box component={Form} width="100%">
            <Stack direction="column" justifyContent="center" spacing={2}>
              <Field
                component={TextInput}
                name="search"
                label="Pesquisar Escola"
                variant="outlined"
                autoComplete="off"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl fullWidth>
                <Field
                  component={SelectInput}
                  name="status"
                  label="Filtrar por Status"
                  variant="outlined"
                >
                  <MenuItem value={SchoolStatusEnum.pending}>Pendente</MenuItem>
                  <MenuItem value={SchoolStatusEnum.scheduled}>
                    Agendada
                  </MenuItem>
                  <MenuItem value={SchoolStatusEnum.visited}>
                    Entrevistada
                  </MenuItem>
                </Field>
              </FormControl>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                width="100%"
              >
                <Button
                  variant="outlined"
                  fullWidth
                  disableElevation
                  onClick={() => {
                    setFieldValue("search", "");
                    setFieldValue("status", "");

                    submitForm();
                  }}
                >
                  Limpar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disableElevation
                  onClick={submitForm}
                >
                  Filtrar
                </Button>
              </Stack>
            </Stack>
          </Box>
        )}
      </Formik>
      <Divider sx={{ width: "100%", marginY: 2 }} />
      <Box width="100%">
        {(allSchools.length || 0) > 0 ? (
          allSchools.map((school, index) => (
            <Card
              key={`${school.coSchool}-${index}`}
              variant="outlined"
              sx={{
                borderRadius: 4,
                width: "100%",
                bgcolor: "transparent",
                ...(allSchools.length - 1 !== index ? { marginBottom: 2 } : {}),
              }}
            >
              <Box
                width="100%"
                color="white"
                bgcolor={getSchoolStatusColor(school.stResearchStatus)}
                textAlign="center"
              >
                {getSchoolStatus(school.stResearchStatus)}
              </Box>
              {school.substituteSchool ? (
                <Box
                  width="100%"
                  color="white"
                  bgcolor="secondary.main"
                  textAlign="center"
                >
                  Escola Substituta
                </Box>
              ) : null}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
                spacing={2}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Typography
                    fontWeight="bold"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                  >
                    {school.dsName}
                  </Typography>
                  <Typography>
                    {school.dsCity} / {school.dsState}
                  </Typography>
                  <Typography>INEP: {school.dsInepCode}</Typography>
                  {school.appointment?.dtAppointment &&
                  school.appointment?.dtTime ? (
                    <Typography>
                      {parseDate(school.appointment.dtAppointment)} -{" "}
                      {parseTime(school.appointment.dtTime)}
                    </Typography>
                  ) : null}
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Divider orientation="vertical" flexItem />
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    width={100}
                    spacing={2}
                  >
                    {SchoolStatusEnum[school.stResearchStatus] === "pending" ? (
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        fullWidth
                        onClick={() => handleOnClickSchedule(school)}
                      >
                        Agendar
                      </Button>
                    ) : SchoolStatusEnum[school.stResearchStatus] ===
                      "visited" ? (
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        fullWidth
                        onClick={() => handleOnClickFeedback(school)}
                      >
                        Feedback
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="warning"
                          disableElevation
                          fullWidth
                          onClick={() => handleOnClickReplace(school)}
                        >
                          Substituir
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          disableElevation
                          fullWidth
                          onClick={() => handleOnClickCancel(school)}
                        >
                          Cancelar
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          fullWidth
                          onClick={() => handleOnClickVisit(school)}
                        >
                          Visitar
                        </Button>
                      </>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          ))
        ) : !isLoadingSchools ? (
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={4}
          >
            <Box
              component="img"
              alt="Logo FNDE"
              src="/assets/svg/empty.svg"
              sx={{
                objectFit: "cover",
                width: {
                  xs: "100%",
                  sm: "50%",
                },
                maxWidth: {
                  xs: 200,
                  sm: 300,
                },
              }}
            />
            <Typography variant="h6">Nenhuma escola foi encontrada!</Typography>
          </Stack>
        ) : null}
        {isLoadingSchools ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            marginTop={2}
          >
            <CircularProgress size="2rem" />
            <Typography>Carregando...</Typography>
          </Stack>
        ) : null}
      </Box>
    </Box>
  );
};

export default View;
