import React, { useEffect, useState } from "react";

import { Navigate, Outlet } from "react-router-dom";

import { jwtDecode } from "jwt-decode";

import { Box, CircularProgress } from "@mui/material";

import { useToast } from "@/contexts/ToastContext";

import { JwtPayload } from "@/types/Jwt";
import { ProfileEnum } from "@/enums/Profile";

const PrivateRoute: React.FC = () => {
  const toast = useToast();

  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);

  useEffect(() => {
    const accessToken =
      localStorage.getItem("access-token") ||
      sessionStorage.getItem("access-token") ||
      "";

    if (!accessToken) {
      setIsAuthorized(false);
      return;
    }

    try {
      const { user_profile } = jwtDecode<JwtPayload>(accessToken);
      const profile = ProfileEnum[user_profile];

      if (!profile) {
        setIsAuthorized(false);
        return;
      }

      const redirects = {
        manager: {
          base: "/gerenciamento/gestor",
          initial: "/gerenciamento/gestor/dashboard",
        },
        coordinator: {
          base: "/gerenciamento/coordenador",
          initial: "/gerenciamento/coordenador/usuarios",
        },
        researcher: {
          base: "/pesquisador",
          initial: "/pesquisador/escolas",
        },
      };

      const redirect = redirects[profile as keyof typeof redirects];
      if (!window.location.pathname.startsWith(redirect.base)) {
        setIsAuthorized(false);
        window.location.replace(redirect.initial);
      } else {
        setIsAuthorized(true);
      }
    } catch {
      toast.error("Não foi possível decodificar o token de acesso.");

      setIsAuthorized(false);
    }
  }, []);

  if (isAuthorized === null) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return isAuthorized ? <Outlet /> : <Navigate to="/acesso/entrar" />;
};

export default PrivateRoute;
