import http from "@/api/http";

import {
  postFeedbackPayload,
  postFeedbackResponse,
  getFeedbackDownloadPayload,
  getFeedbackDownloadResponse,
} from "./types";

export const postFeedback = async (
  payload: postFeedbackPayload
): Promise<postFeedbackResponse> => {
  const response = await http.post<postFeedbackResponse>("/feedback/", payload);

  return response.data;
};

export const getFeedbackDownload = async (
  payload: getFeedbackDownloadPayload
): Promise<getFeedbackDownloadResponse> => {
  const response = await http.get<getFeedbackDownloadResponse>(
    "/feedback/download/",
    { params: payload }
  );

  return response.data;
};
