import React from "react";

import {
  Card,
  CardContent,
  Typography,
  Box,
  LinearProgress,
  useTheme,
} from "@mui/material";

import { InfoBoxProps } from "./types";

const InfoBox: React.FC<InfoBoxProps> = ({ title, value, percentage }) => {
  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      sx={{
        padding: 2,
        borderRadius: 4,
      }}
    >
      <CardContent>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={2}
        >
          <Box textAlign="right">
            <Typography
              variant="h4"
              component="span"
              sx={{ color: theme.palette.text.primary }}
            >
              {value}
            </Typography>
            <Typography
              variant="subtitle1"
              component="span"
              sx={{
                fontWeight: 500,
                marginLeft: 1,
              }}
            >
              ({percentage.toFixed(1)}%)
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.text.secondary }}
          >
            {title}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={percentage}
            sx={{
              height: 8,
              borderRadius: 4,
              marginTop: 1,
              backgroundColor: theme.palette.grey[300],
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default InfoBox;
