import React, { useEffect, useState } from "react";

import { jwtDecode } from "jwt-decode";
import { Outlet, useLocation } from "react-router-dom";

import { blue } from "@mui/material/colors";
import {
  Link,
  Stack,
  Box,
  Button,
  Tooltip,
  Typography,
  Divider,
} from "@mui/material";
import {
  Menu,
  DashboardOutlined,
  PeopleOutlined,
  SchoolOutlined,
} from "@mui/icons-material";

import { JwtPayload } from "@/types/Jwt";
import { ProfileEnum } from "@/enums/Profile";

import { statesLabels } from "@/data/states";

import ProfileMenu from "@/common/components/ProfileMenu";

const getProfileMenuItems = (profile: string) => {
  switch (profile) {
    case "manager":
      return [
        {
          subtitle: "Gerenciamento",
          links: [
            {
              id: 1,
              label: "Dashboard",
              url: "/gerenciamento/gestor/dashboard",
              icon: <DashboardOutlined fontSize="small" />,
            },
            {
              id: 2,
              label: "Usuários",
              url: "/gerenciamento/gestor/usuarios",
              icon: <PeopleOutlined fontSize="small" />,
            },
          ],
        },
      ];
    case "coordinator":
      return [
        {
          subtitle: "Gerenciamento",
          links: [
            {
              id: 1,
              label: "Escolas",
              url: "/gerenciamento/coordenador/escolas",
              icon: <SchoolOutlined fontSize="small" />,
            },
            {
              id: 2,
              label: "Usuários",
              url: "/gerenciamento/coordenador/usuarios",
              icon: <PeopleOutlined fontSize="small" />,
            },
          ],
        },
      ];
    default:
      return [];
  }
};

const TemplateAdmin: React.FC = () => {
  const location = useLocation();
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const accessToken =
    localStorage.getItem("access-token") ||
    sessionStorage.getItem("access-token") ||
    "";

  const decoded = jwtDecode<JwtPayload>(accessToken);

  const menuItems = getProfileMenuItems(ProfileEnum[decoded.user_profile]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [location]);

  return (
    <Stack direction="row" minHeight="100svh" bgcolor="white" padding={2}>
      <Stack
        direction="column"
        spacing={2}
        marginRight={2}
        minWidth={isSidebarOpen ? 200 : 40}
        width={isSidebarOpen ? 200 : 40}
        sx={{
          transition: "width 0.5s",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <Link href="/pesquisador" underline="none">
          <Box
            component="img"
            alt="Logo ColetaPNLD"
            src={
              isSidebarOpen
                ? "/assets/img/logo-coletapnld.png"
                : "/assets/img/logo-coletapnld-mini.png"
            }
            sx={{
              height: 50,
              width: isSidebarOpen ? "auto" : 40,
              maxWidth: "100%",
              objectFit: "contain",
            }}
          />
        </Link>
        <Stack direction="column" spacing={1} width="100%">
          {menuItems.map((section, index) => (
            <React.Fragment key={index}>
              {section.subtitle &&
                (isSidebarOpen ? (
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "text.secondary",
                      textTransform: "uppercase",
                      fontSize: 12,
                    }}
                  >
                    {section.subtitle}
                  </Typography>
                ) : index !== 0 ? (
                  <Divider />
                ) : null)}
              {section.links.map((link) => {
                const isActive = location.pathname.startsWith(link.url);

                return (
                  <Link
                    key={link.id}
                    href={link.url}
                    underline="none"
                    sx={{
                      color: isActive ? "primary.main" : "text.secondary",
                      fontWeight: isActive ? "bold" : "normal",
                      display: "flex",
                      alignItems: "center",
                      gap: isSidebarOpen ? 1 : 0,
                      padding: 1.5,
                      width: "100%",
                      justifyContent: isSidebarOpen ? "flex-start" : "center",
                      backgroundColor: isActive ? blue[50] : "transparent",
                      borderRadius: 2,
                      transition: "padding 0.5s",
                      "&:hover": {
                        backgroundColor: "action.hover",
                      },
                    }}
                  >
                    <Tooltip
                      title={isSidebarOpen ? "" : link.label}
                      placement="right"
                    >
                      {link.icon}
                    </Tooltip>
                    {isSidebarOpen && (
                      <Box
                        sx={{
                          opacity: isSidebarOpen ? 1 : 0,
                          transition: "opacity 0.5s",
                        }}
                      >
                        {link.label}
                      </Box>
                    )}
                  </Link>
                );
              })}
            </React.Fragment>
          ))}
        </Stack>
      </Stack>

      <Stack direction="column" flexGrow={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          sx={{ height: 50 }}
        >
          <Button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            variant="contained"
            disableElevation
            sx={{ minWidth: "auto", padding: 0.5 }}
          >
            <Menu />
          </Button>
          <ProfileMenu />
        </Stack>

        <Stack
          direction="row"
          width="100%"
          bgcolor="white"
          marginTop={2}
          flexGrow={1}
        >
          <Box
            ref={scrollContainerRef}
            bgcolor="background.default"
            overflow="auto"
            borderRadius={4}
            padding={4}
            flexGrow={1}
          >
            <Box maxWidth="lg" margin="auto" height="100%">
              <Outlet />
            </Box>
          </Box>
        </Stack>

        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          padding={2}
          paddingBottom={0}
          spacing={2}
        >
          <Box
            component="img"
            alt="Logo FNDE"
            src="/assets/img/logo-fnde.png"
            sx={{
              objectFit: "cover",
              height: {
                xs: 40,
                sm: 50,
              },
            }}
          />
          <Box
            component="img"
            alt="Logo NEES"
            src="/assets/img/logo-nees.png"
            sx={{
              objectFit: "cover",
              height: {
                xs: 40,
                sm: 50,
              },
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TemplateAdmin;
