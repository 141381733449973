import React from "react";

import { Typography, FormControl } from "@mui/material";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";

import { getIn } from "formik";

import { TextInputProps } from "./types";

export function fieldToTextInput({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  helperText,
  ...props
}: TextInputProps): TextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    ...field,
    ...props,
  };
}

export function TextInput({
  children,
  label,
  required,
  ...props
}: TextInputProps) {
  const textFieldProps = fieldToTextInput(props);

  const touched = getIn(props.form.touched, props.field.name);
  const error = getIn(props.form.errors, props.field.name);

  return (
    <FormControl fullWidth>
      {label && (
        <Typography
          variant="subtitle1"
          gutterBottom
          color={touched && error ? "error" : "inherit"}
        >
          {label}
          {required && (
            <Typography display="inline" color="error" marginLeft={1}>
              *
            </Typography>
          )}
        </Typography>
      )}
      <MuiTextField {...textFieldProps}>{children}</MuiTextField>
    </FormControl>
  );
}
