import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Field, Form, Formik, FormikHelpers } from "formik";

import {
  Box,
  Stack,
  Typography,
  MenuItem,
  FormControl,
  Button,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import DataTable from "@/common/components/DataTable";
import { SelectInput } from "@/components/Inputs/SelectInput";

import { statesOptions, statesLabels } from "@/data/states";
import { SchoolDashboard } from "@/types/School";
import { SchoolStatusEnum } from "@/enums/School";

import { useInfiniteQueryDashboardSchoolsByStatus } from "@/api/dashboard/queries";

export interface DashboardFormData {
  state: string;
  status: string;
}

const initialValues: DashboardFormData = { state: "", status: "" };

const validationSchema = Yup.object({
  state: Yup.string(),
  status: Yup.string(),
});

const ByStatus: React.FC = () => {
  const [selectedState, setSelectedState] = useState("" as string);
  const [selectedStatus, setSelectedStatus] = useState("" as string);

  const [allDashboardSchoolsPending, setAllDashboardSchoolsPending] = useState(
    [] as SchoolDashboard[]
  );
  const [allDashboardSchoolsScheduled, setAllDashboardSchoolsScheduled] =
    useState([] as SchoolDashboard[]);
  const [allDashboardSchoolsVisited, setAllDashboardSchoolsVisited] = useState(
    [] as SchoolDashboard[]
  );
  const [allDashboardSchoolsFinished, setAllDashboardSchoolsFinished] =
    useState([] as SchoolDashboard[]);

  const { data: infiniteDashboardSchoolsPending, isLoading: isLoadingPending } =
    useInfiniteQueryDashboardSchoolsByStatus({
      dsState: selectedState,
      stResearchStatus: SchoolStatusEnum.pending,
    });
  const {
    data: infiniteDashboardSchoolsScheduled,
    isLoading: isLoadingScheduled,
  } = useInfiniteQueryDashboardSchoolsByStatus({
    dsState: selectedState,
    stResearchStatus: SchoolStatusEnum.scheduled,
  });
  const { data: infiniteDashboardSchoolsVisited, isLoading: isLoadingVisited } =
    useInfiniteQueryDashboardSchoolsByStatus({
      dsState: selectedState,
      stResearchStatus: SchoolStatusEnum.visited,
    });
  const {
    data: infiniteDashboardSchoolsFinished,
    isLoading: isLoadingFinished,
  } = useInfiniteQueryDashboardSchoolsByStatus({
    dsState: selectedState,
    stResearchStatus: SchoolStatusEnum.finished,
  });

  const headers = [
    { name: "Nome", key: "dsName" },
    { name: "Cidade", key: "dsCity" },
    { name: "Estado", key: "dsState" },
    { name: "Usuário vinculado", key: "linkedUser" },
  ];

  const statusOptions = [
    {
      value: SchoolStatusEnum.pending,
      label: "Pendente",
      data: allDashboardSchoolsPending,
      isLoading: isLoadingPending,
    },
    {
      value: SchoolStatusEnum.scheduled,
      label: "Agendada",
      data: allDashboardSchoolsScheduled,
      isLoading: isLoadingScheduled,
    },
    {
      value: SchoolStatusEnum.visited,
      label: "Entrevistada",
      data: allDashboardSchoolsVisited,
      isLoading: isLoadingVisited,
    },
    {
      value: SchoolStatusEnum.finished,
      label: "Finalizada",
      data: allDashboardSchoolsFinished,
      isLoading: isLoadingFinished,
    },
  ] as {
    value: number;
    label: string;
    data: SchoolDashboard[];
    isLoading: boolean;
  }[];

  const handleOnSubmit = (
    values: DashboardFormData,
    { setSubmitting }: FormikHelpers<DashboardFormData>
  ) => {
    setSelectedState(values.state);
    setSelectedStatus(values.status);
    setSubmitting(false);
  };

  useEffect(() => {
    if (!infiniteDashboardSchoolsPending) return;

    const flatDashboardSchoolsPending =
      infiniteDashboardSchoolsPending.pages.flatMap((page) => page.results);

    setAllDashboardSchoolsPending(flatDashboardSchoolsPending);
  }, [infiniteDashboardSchoolsPending]);

  useEffect(() => {
    if (!infiniteDashboardSchoolsScheduled) return;

    const flatDashboardSchoolsScheduled =
      infiniteDashboardSchoolsScheduled.pages.flatMap((page) => page.results);

    setAllDashboardSchoolsScheduled(flatDashboardSchoolsScheduled);
  }, [infiniteDashboardSchoolsScheduled]);

  useEffect(() => {
    if (!infiniteDashboardSchoolsVisited) return;

    const flatDashboardSchoolsVisited =
      infiniteDashboardSchoolsVisited.pages.flatMap((page) => page.results);

    setAllDashboardSchoolsVisited(flatDashboardSchoolsVisited);
  }, [infiniteDashboardSchoolsVisited]);

  useEffect(() => {
    if (!infiniteDashboardSchoolsFinished) return;

    const flatDashboardSchoolsFinished =
      infiniteDashboardSchoolsFinished.pages.flatMap((page) => page.results);

    setAllDashboardSchoolsFinished(flatDashboardSchoolsFinished);
  }, [infiniteDashboardSchoolsFinished]);

  const renderDataTable = (
    title: string,
    data: SchoolDashboard[],
    isLoading: boolean
  ) => {
    const dataFormatted = data.map((school) => ({
      ...school,
      dsState: school.dsState
        ? statesLabels[
            school.dsState.toUpperCase() as keyof typeof statesLabels
          ] || "(Estado inválido)"
        : "(Sem estado)",
      linkedUser: school.linkedUser ? school.linkedUser : "(Sem usuário)",
    }));

    return (
      <Stack direction="column" spacing={3}>
        <Typography variant="h6">Escolas ({title})</Typography>
        <Stack direction="column">
          <Divider />
          <DataTable
            headers={headers}
            rowId="coSchool"
            data={dataFormatted}
            isLoading={isLoading}
          />
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack spacing={3} paddingX={2} paddingBottom={2}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Box component={Form} width="100%">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Stack direction="row" spacing={2}>
                <FormControl sx={{ width: 200 }}>
                  <Field
                    component={SelectInput}
                    name="state"
                    size="small"
                    variant="outlined"
                    displayEmpty
                  >
                    <MenuItem value="">Selecione um estado</MenuItem>
                    {statesOptions.map((state) => (
                      <MenuItem key={state.value} value={state.value}>
                        {state.label}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                <FormControl sx={{ width: 200 }}>
                  <Field
                    component={SelectInput}
                    name="status"
                    size="small"
                    variant="outlined"
                    displayEmpty
                  >
                    <MenuItem value="">Selecione um status</MenuItem>
                    <MenuItem value={SchoolStatusEnum.pending}>
                      Pendente
                    </MenuItem>
                    <MenuItem value={SchoolStatusEnum.scheduled}>
                      Agendada
                    </MenuItem>
                    <MenuItem value={SchoolStatusEnum.visited}>
                      Entrevistada
                    </MenuItem>
                    <MenuItem value={SchoolStatusEnum.finished}>
                      Finalizada
                    </MenuItem>
                  </Field>
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  disableElevation
                  onClick={() => {
                    setFieldValue("state", "");
                    setFieldValue("status", "");
                    setSelectedState("");
                    setSelectedStatus("");
                  }}
                  sx={{ paddingX: 4 }}
                >
                  Limpar
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  disableElevation
                  loading={isSubmitting}
                  sx={{ paddingX: 4 }}
                >
                  Filtrar
                </LoadingButton>
              </Stack>
            </Stack>
          </Box>
        )}
      </Formik>

      {selectedStatus === ""
        ? statusOptions.map((status) =>
            renderDataTable(status.label, status.data, status.isLoading)
          )
        : renderDataTable(
            statusOptions.find(
              (status) => status.value === Number(selectedStatus)
            )?.label || "",
            statusOptions.find(
              (status) => status.value === Number(selectedStatus)
            )?.data || [],
            statusOptions.find(
              (status) => status.value === Number(selectedStatus)
            )?.isLoading || false
          )}
    </Stack>
  );
};

export default ByStatus;
